.drawer {
  height: 100%;
}

.drawer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.drawer.Modal_description {
  /* display: flex;
  align-items: center; */
  display: flex;
  align-items: center;
  top: 20%;
  position: absolute;
  width: 90%;
  left: 0;
  height: 40%;
}

.drawer-image {
  position: absolute;
  height: 25%;
  z-index: 10;
  margin: 13% 19%;
}

.drawer-image img {
  height: 30%;
}

.drawer-inputs {
  /* position: absolute;
  margin-top: 8%;
  z-index: 11;
  display: flex;
  left: 0;
  width: 100%; */
  position: absolute;
  margin-top: 15%;
  z-index: 11;
  display: flex;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40%;
}

/* Disabled arrow on input */
/* Chrome, Safari, Edge, Opera */
.drawer-inputs input::-webkit-outer-spin-button,
.drawer-inputs input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.drawer-inputs input[type='number'] {
  -moz-appearance: textfield;
}
