.box {
  margin: 20%;
  .contents {
    .object-content {
      text-decoration: none;
      background-color: none;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 50%;
        margin: 20px;
      }
    }
  }
}
