@font-face {
  font-family: 'segment-standard';
  src: url('../../../../assets/fonts/Segment7Standard.otf');
}

.enigma-container {
  height: 100%;
  width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.phone-container {
  height: 75%;
  width: 100%;
}

.top-part {
  width: 100%;
  height: 19%;
  display: flex;
  background: #2f0a4c;
  border-radius: 10px;
  padding: 5%;
  container-type: inline-size;

  div {
    font-family: 'segment-standard', 'Montserrat', 'Comfortaa', Arial, Helvetica,
      sans-serif;
    width: 100%;
    background: #7a5bb0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 35px;
    padding-right: 0.5rem;
  }

  @container (width < 230px) {
    div {
      font-size: 31px;
    }
  }
}

.bottom-part {
  padding: 20px 36px;
  width: 100%;
  height: 70%;
  background: #2f0a4c;
  border-radius: 10px;
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 9px;

  button {
    border: none;
    padding: 0;
    background: #7957af;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      color: #7957af;
      background: #ffffff;
    }
  }

  .hang {
    img {
      width: 46.2px;
      height: 46.2px;
    }
  }

  .pick {
    img {
      width: 46.24px;
      height: 46.15px;
      padding: 7px;
    }
  }

  .number {
    color: #ffffff;
    font-weight: 700;
    font-size: 30px;

    span {
      vertical-align: sub;
    }
  }
}
