.modal-head {
  width: 100%;
  background-color: #764cef;
  border-radius: 20px 20px 0px 0px;

  .small-buttons {
    padding: 2% 1rem;
    display: flex;
    align-items: center;
    height: 100%;

    div {
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin: 0.25rem;

      &:first-of-type {
        background-color: #f7472f;
      }

      &:nth-of-type(2) {
        background-color: #ffca40;
      }

      &:last-of-type {
        background-color: #6cf756;
      }
    }
  }
}
