.note {
  color: white;
  font-size: x-large;
  background-color: rgba(0, 0, 0, 0.2901960784);
  border-radius: 10px;
  padding: 5px 30px;
  width: 80%;
  position: absolute;
  top: 10%;
  left: 5%;
  .list-response {
    display: flex;
    justify-content: center;
    .responses {
      display: flex;
      align-items: center;
      :nth-child(n) {
        display: flex;
        align-items: center;
      }
      :first-child .bar {
        background-color: transparent;
      }
      .bar {
        height: 6px;
        width: 30px;
        background-color: white;
      }
      .response {
        height: 28px;
        width: 28px;
        background-color: white;
        border-radius: 50%;
        text-decoration: none;
        border: none;
        cursor: pointer;
      }
      .select-choice {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        position: absolute;
        margin-left: 34px;
        cursor: pointer;
        background: linear-gradient(270deg, #f7931e -148.21%, #f15a24 253.52%);
      }
    }
    .response-maximum {
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .note {
    width: 60%;
    .list-response {
      .responses {
        .bar {
          height: 3px;
          width: 10px;
        }
        .response {
          height: 18px;
          width: 18px;
        }
        .select-choice {
          height: 10px;
          width: 10px;
          margin-left: 14px;
        }
      }
    }
  }
}
