.garlands-idle {
  position: absolute;
  top: 14%;
  left: 23.9%;
  width: 49%;
}
.garlands-switchedon {
  position: absolute;
  top: 12.6%;
  left: 22.9%;
  width: 51%;
}
