.BreakRoomRPS {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 102%;
}

.BreakRoom-img-div {
  position: relative;
  display: flex;
  height: 100%;
  width: calc((8 / 3) * 102vh);
}

.center {
  transform: translateX(calc((-8 / 3) * 85vh + 50vw));
}

.right-from-center {
  animation: goRightFromCenterBreakRoom 1s ease forwards;
}
.center-from-left {
  animation: goCenterFromLeftBreakRoom 1s ease forwards;
}
.left-from-center {
  animation: goLeftFromCenterBreakRoom 1s ease forwards;
}
.center-from-right {
  animation: goCenterFromRightBreakRoom 1s ease forwards;
}

@keyframes goRightFromCenterBreakRoom {
  from {
    transform: translateX(calc((-8 / 3) * 85vh + 50vw));
  }
  to {
    transform: translateX(calc((-8 / 3) * 139vh + 50vw));
  }
}
@keyframes goCenterFromLeftBreakRoom {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc((-8 / 3) * 85vh + 50vw));
  }
}
@keyframes goLeftFromCenterBreakRoom {
  from {
    transform: translateX(calc((-8 / 3) * 85vh + 50vw));
  }
  to {
    transform: translateX(0);
  }
}
@keyframes goCenterFromRightBreakRoom {
  from {
    transform: translateX(calc((-8 / 3) * 139vh + 50vw));
  }
  to {
    transform: translateX(calc((-8 / 3) * 85vh + 50vw));
  }
}

@keyframes opacityOn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes opacityOff {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.BreakRoom-img header {
  display: flex;
  position: absolute;
  width: 100%;
}

.BreakRoom_object--isDisplay {
  position: absolute;
}

.BreakRoom_object--isNotDisplay,
.BreakRoom_object--isDisplayCode {
  position: absolute;
  opacity: 0;
}

.BreakRoom_object--isNotDisplay {
  display: none;
}

.BreakRoom_object--isDisplayCode:hover {
  position: absolute;
  opacity: 1;
}

.BottomInventory {
  border-radius: 1vh;
  display: flex;
  justify-content: center;
  z-index: 1000;
  margin-top: 1.1vh;
}

.BottomInventory .Inventory {
  border-radius: 5px;
}

.BottomInventory p {
  font-size: 1.2vw;
}

.Acomplissement {
  position: relative;
  bottom: 20%;
}
