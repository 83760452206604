.Fridge {
  display: flex;
  justify-content: center;
  width: 75%;
}

.Fridge_image {
  height: 80%;
  width: 100%;
  position: absolute;
}

.Fridge_enigma {
  position: relative;
  display: flex;
  padding-top: 3%;
  width: 80%;
  justify-content: center;
}

.Fridge_enigma-right,
.Fridge_enigma-left {
  position: absolute;
}

.Fridge_enigma-left-sentence {
  color: #000 !important;
  text-align: center;
  font-size: 1.5vw;
  font-weight: bold !important;
}

.Fridge_enigma-BKG {
  width: 100%;
}

.Fridge_enigma-right {
  width: 27%;
  top: 48%;
  right: 10%;
}
.Fridge_enigma-left {
  width: 45%;
  top: 18%;
  left: 50%;
  transform: translateX(-100%);
}

.Fridge_enigma-left-list {
  width: 100%;
}

.Fridge_enigma-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.Fridge_enigma-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3% 4%;
  background-color: transparent;
  border: 1px solid transparent;
}
.Fridge_enigma-item img {
  width: 2vw;
  height: 2vw;
}

.Fridge_enigma-itemClicked {
  background: rgba(196, 196, 196, 0.2);
  border: 1px solid #bebdbd;
  border-radius: 50px;
}

.Fridge_enigma-kcal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 111%;
  left: 2%;
}
.Fridge_enigma-kcal p {
  margin-bottom: 2vw !important;
}

.Fridge_enigma-Quantity {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  background: #545454;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  padding: 15px;
}
.Fridge_enigma-Quantity p {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold !important;
  padding: 10px 0px;
}
.Fridge_enigma-Quantity .Input {
  display: flex;
  justify-content: center;
}

input#quantity.Input_content {
  width: 90%;
}

.Fridge_enigma-gramme {
  display: flex;
  color: #fff;
}

.Fridge_enigma-kcal-Save {
  background-image: linear-gradient(
    261.23deg,
    #fdf53f -32.73%,
    #e0615d 84.04%,
    #d93c65 112.85%
  );
  border-radius: 10px;
  border: none;
  padding: 5px 10px;
  color: #fff;
  margin-right: 15px;
}

.Fridge_enigma-kcal-Buttons {
  display: flex;
}

.Fridge_enigma-kcal-Reset {
  background: transparent;
  border: 2px solid #fff;
  border-radius: 10px;
  font-size: 1.1vw;
  padding: 5px 10px;
  color: #fff;
}
.Fridge_enigma-kcal-Reset:hover {
  background: rgba(255, 255, 255, 0.2);
}
.Fridge_enigma-kcal-Reset:active {
  background: rgba(255, 255, 255, 0.4);
}
