.DocumentHolder {
  height: 100%;
}

.DocumentHolder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.DocumentHolder.Modal_description {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DocumentHolder_background {
  height: 100%;
  width: 80%;
  margin: 5%;
  display: flex;
}

.DocumentHolder_background-left {
  position: absolute;
  width: 30%;
  left: 50%;
  height: 68%;
  transform: translateX(-100%);
}

.DocumentHolder_background-right {
  position: absolute;
  z-index: 5;
  width: 25.6%;
  left: 50%;
  height: 65%;
  top: 13%;
  transform: translateX(12%);
}

.DocumentHolder_background-left-field img:nth-child(1) {
  position: absolute;
  width: 40%;
  left: 4%;
  top: 4%;
}
.DocumentHolder_background-left-field img:nth-child(2) {
  position: absolute;
  width: 50%;
  right: 8%;
  top: 20%;
}
.DocumentHolder_background-left-field img:nth-child(3) {
  position: absolute;
  width: 45%;
  left: 10%;
  bottom: 4%;
}

.DocumentHolder_background-right-tablet {
  border-radius: 1vw;
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #000;
}

.DocumentHolder_background-right-tablet-switch {
  border: 1px solid black;
  border-radius: 30px;
  text-decoration: none;
  height: 30px;
  width: 30px;
  background-color: transparent;
  margin-bottom: 5%;
}

.DocumentHolder_background-right-tablet-content {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 5%;
  justify-content: center;
  align-items: center;
}

.DocumentHolder_background-right-tablet-content p {
  color: black !important;
  font-size: 1.2vw;
}

.DocumentHolder_background-img {
  position: relative;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.DocumentHolder-title {
  font-size: 1.6vw !important;
  font-weight: bold !important;
  padding-bottom: 0.5vw !important;
  border-bottom: 1px solid #ceceec;
}
.DocumentHolder-titl2 {
  font-size: 1vw !important;
  padding: 0.5vw !important;
  border-bottom: 1px solid #ceceec;
}

.DocumentHolder-bloc {
  padding: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1vw !important;
}

.DocumentHolder-bloc label {
  font-size: 0.8vw !important;
}
