.checkroom {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .footer {
    position: absolute;
    bottom: 2%;
    margin: 0 5%;
    p {
      color: white;
      text-align: center;
      font-weight: 300;
      font-size: 32px;
      line-height: 39px;
      background-color: rgba(0, 0, 0, 0.2901960784);
      padding: 2%;
    }
  }
}
