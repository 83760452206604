.boardsun {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.boardsun-enigma {
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
}

.boardsun-enigma-left {
  display: flex;
}

.boardsun-enigma-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: absolute;
  right: 0;
  width: 30%;
  height: 30vw;
  top: 50%;
  transform: translateY(-50%);
}

.boardsun-enigma-right-remote-shutdown {
  width: 40%;
  background-color: transparent;
  border: none;
}

.boardsun-enigma-right-remote-shutdown img {
  width: 100%;
}

.boardsun-enigma-right-remote-go-back {
  width: 40%;
  background-color: transparent;
  border: none;
}

.boardsun-enigma-right-remote-go-back img {
  text-decoration: none;
  border: none;
  width: 40%;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
}

.boardsun-enigma-right-remote-arrow {
  cursor: pointer;
  display: flex;
}

.boardsun-enigma-left-description {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.boardsun-enigma-left-left {
  align-items: center;
  display: flex;
  position: absolute;
  z-index: 3;
  top: 10%;
  left: 7%;
}

.boardsun-enigma-left-left p {
  font-size: 2.5vw;
  font-weight: bold !important;
}

.boardsun-enigma-left-left img {
  height: 100px;
}

.boardsun-enigma-left-right {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 50%;
  height: 32vw;
  transform: translateY(-50%);
}

.boardsun-enigma-left-right-top {
  display: flex;
  position: absolute;
  top: 35%;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
}

.boardsun-enigma-left-right-bottom {
  display: flex;
  position: absolute;
  top: 70%;
  width: 40%;
  left: 7%;
}

.boardsun-enigma-left-right-bottom-text {
  display: flex;
  position: absolute;
  top: 63%;
  width: 100%;
  left: 8%;
  font-size: 1.6vw;
}

.boardsun-enigma-left-right-top-text {
  display: flex;
  position: absolute;
  top: 28%;
  width: 100%;
  left: 8%;
  font-size: 1.6vw;
}

.boardsun-enigma-left-right-app p,
.boardsun-enigma-left-right-app--active p {
  font-size: 1.8vw;
  font-weight: bold !important;
}

.boardsun-enigma-left-right-app img,
.boardsun-enigma-left-right-app--active img {
  width: 20%;
  margin-right: 1vw;
}

.boardsun-enigma-left-right-app {
  display: flex;
  justify-content: center;
  border-radius: 1vw;
  align-items: center;
  width: 100%;
  border: 3px solid transparent;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 2%;
  height: 5vw;
}

.boardsun-enigma-left-right-app--active {
  display: flex;
  justify-content: center;
  border: 3px solid white;
  border-radius: 1vw;
  align-items: center;
  width: 100%;
  margin: 2%;
  height: 5vw;
  background-color: rgba(255, 255, 255, 0.5);
}

.boardsun-enigma-container-tvbackground {
  position: relative;
  width: 60vw;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
}

.boardsun-enigma-right-remote-arrow-up {
  width: 40%;
  background-color: transparent;
  border: none;
}

.boardsun-enigma-right-remote-arrow-up img {
  position: absolute;
  width: 40%;
  top: 42%;
  left: 50%;
  transform: translateX(-50%);
}

.boardsun-enigma-right-remote-arrow-down {
  width: 40%;
  background-color: transparent;
  border: none;
}

.boardsun-enigma-right-remote-arrow-down img {
  position: absolute;
  width: 40%;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
}

.boardsun-enigma-right-remote-arrow-left {
  width: 40%;
  background-color: transparent;
  border: none;
}

.boardsun-enigma-right-remote-arrow-left img {
  position: absolute;
  width: 20%;
  top: 50%;
  left: 15%;
}

.boardsun-enigma-right-remote-arrow-right {
  width: 40%;
  background-color: transparent;
  border: none;
}

.boardsun-enigma-right-remote-arrow-right img {
  position: absolute;
  width: 20%;
  top: 50%;
  right: 15%;
}

.boardsun-enigma-right-remote-center {
  width: 40%;
  background-color: transparent;
  border: none;
}

.boardsun-enigma-right-remote-center img {
  position: absolute;
  width: 23%;
  top: 54%;
  left: 50%;
  transform: translateX(-50%);
}

.boardsun-enigma-animation {
  position: absolute;
  width: 66%;
  left: 50%;
  transform: translateX(-69%) translateY(-52%);
  height: 31.8vw;
  top: 49.5%;
  border-radius: 6%;
  z-index: 5;
}

.boardsun-enigma-unavailable {
  position: absolute;
  font-size: 1.6vw;
  font-weight: bold !important;
  left: 50%;
  top: 50%;
  transform: translateX(-70%) translateY(-50%);
}
