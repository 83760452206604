/* REFACTO obesity css to delete this file */
.Gameboard-container {
  border-radius: 5px;
  border: 4px solid #66566d;
  width: 60%;
  background: #66566d;
  position: relative;
  margin: 0px 5px;
  cursor: url('../../img/pink.cur'), default;
  cursor: url('../../img/pink.cur'), pointer;
  overflow-x: hidden;
}

.Gameboard-container-End {
  border-radius: 5px;
  border: 4px solid transparent;
  width: 80%;
  background: #66566d;
  position: relative;
  margin: 0px 5px;
  cursor: url('../../img/pink.cur'), default;
  cursor: url('../../img/pink.cur'), pointer;
  overflow-x: hidden;
}

.Gameboard-container-Turn {
  border-radius: 5px;
  border: 4px solid #66566d;
  width: 60%;
  background: #66566d;
  position: relative;
  margin: 0px 5px;
  cursor: url('../../img/pink.cur'), default;
  cursor: url('../../img/pink.cur'), pointer;
  overflow-x: hidden;
  animation: borderAppear 2s;
}

.GameBoardHeader_cards {
  display: flex;
  width: 28%;
}

.GameBoardHeader_card {
  background-repeat: no-repeat;
}

.GameBoardHeader_card p {
  opacity: 0;
}

.card_primary {
  background-image: url(../../assets/images/emotions/minicard/mini_primary_emotion_card.svg);
  display: flex;
  width: 20%;
  justify-content: center;
  background-position: center;
}

.card_secondary_1 {
  background-image: url(../../assets/images/emotions/minicard/mini_emotion_card_1.svg);
  display: flex;
  width: 20%;
  justify-content: center;
  background-position: center;
}

.card_secondary_2 {
  background-image: url(../../assets/images/emotions/minicard/mini_emotion_card_2.svg);
  display: flex;
  width: 20%;
  justify-content: center;
  background-position: center;
}

.card_secondary_3 {
  background-image: url(../../assets/images/emotions/minicard/mini_emotion_card_3.svg);
  display: flex;
  width: 20%;
  justify-content: center;
  background-position: center;
}

.card_secondary_4 {
  background-image: url(../../assets/images/emotions/minicard/mini_emotion_card_4.svg);
  display: flex;
  width: 20%;
  justify-content: center;
  background-position: center;
}

.GameBoardHeader_card_found {
  background-color: #2d1937;
  border-radius: 20px;
  height: 20px;
  width: 20px;
  color: white;
  margin-top: 25%;
  font-size: smaller;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.card_disabled {
  opacity: 0.3;
}

@keyframes borderAppear {
  0% {
    border: 4px solid transparent;
  }

  12.5% {
    border: 4px solid rgb(255, 0, 0);
  }

  25% {
    border: 4px solid transparent;
  }

  37.5% {
    border: 4px solid rgb(255, 0, 0);
  }

  50% {
    border: 4px solid transparent;
  }

  62.5% {
    border: 4px solid rgb(255, 0, 0);
  }

  75% {
    border: 4px solid transparent;
  }

  87.5% {
    border: 4px solid rgb(255, 0, 0);
  }

  100% {
    border: 4px solid transparent;
  }
}

.Gameboard-container div,
.Gameboard-container img {
  cursor: url('../../img/pink.cur'), default;
  cursor: url('../../img/pink.cur'), pointer;
}

.Gameboard-Header {
  background: #66566d;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 100;
  width: 100%;
}

.Gameboard-Header-RoomName,
.Gameboard-Header-Level {
  color: #fff;
  font-weight: 700;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 1.3vw;
  align-self: center;
  margin: 2%;
}

.Gameboard-Header-RoomName-Turn,
.Gameboard-Header-Level-Turn {
  color: red;
  font-weight: 700;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: 1.3vw;
  align-self: center;
  margin: 2%;
  animation: textAppear 2s;
}

@keyframes textAppear {
  0% {
    color: #fff;
  }

  12.5% {
    color: rgb(255, 0, 0);
  }

  25% {
    color: #fff;
  }

  37.5% {
    color: rgb(255, 0, 0);
  }

  50% {
    color: #fff;
  }

  62.5% {
    color: rgb(255, 0, 0);
  }

  75% {
    color: #fff;
  }

  87.5% {
    color: rgb(255, 0, 0);
  }

  100% {
    color: #fff;
  }
}

.GameBoard-Header-Informations-green p {
  color: #fff !important;
  font-weight: 700 !important;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  font-size: 1.3vw;
  padding: 20px;
  background-color: #2d1937;
}

.GameBoard-Header-Informations-Container {
  display: flex;
  align-self: center;
}

.Gameboard-Header-Turn {
  display: flex;
  align-self: center;
  /* margin-top: 1%; */
}

.Gameboard-Header-Turn-Admin {
  display: flex;
  align-items: center;
}

.Gameboard-Header-P {
  color: #66566d !important;
  font-weight: 700;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  font-size: 1.1vw;
}

.Gameboard-Header-P-Not-Your-Turn {
  color: red;
  font-weight: 700 !important;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  font-size: 1.1vw;
}

.Gameboard-Header-P-Admin {
  color: #66566d !important;
  font-weight: 700 !important;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  font-size: 1.1vw;
}

.Gameboard-Header-P-Not-Your-Turn-Admin {
  color: #fff;
  font-weight: 700 !important;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  font-size: 1.1vw;
}

@keyframes flowAppear {
  0% {
    color: red;
  }

  12.5% {
    color: #66566d;
  }

  25% {
    color: red;
  }

  37.5% {
    color: #66566d;
  }

  50% {
    color: red;
  }

  62.5% {
    color: #66566d;
  }

  75% {
    color: red;
  }

  87.5% {
    color: #66566d;
  }

  100% {
    color: red;
  }
}

.GameBoard-Header-Informations-red p {
  color: red !important;
  font-weight: 700 !important;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  font-size: 1.3vw;
  padding: 20px;
  background-color: #2d1937;
}

.Gameboard-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Gameboard-container h1,
h2,
h3 {
  font-weight: 700;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  font-size: 1.5vw;
  margin: 15px;
}

.Gameboard-container p {
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0px;
}

.Gameboard-MuteSound img {
  position: absolute;
  width: 15px;
  right: 1%;
  bottom: 1%;
  cursor: pointer;
}

.Gameinfo {
  margin: 10px 30px;
  padding: 10px;
  background: #2d1837;
}

.Gameinfo p {
  margin: 15px;
}

.Timer h1 {
  font-family: 'Roboto';
  text-align: center;
  margin-bottom: 40px;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 1.5vw;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}

.button-wrapper {
  text-align: center;
  margin-top: 40px;
}

.cursor {
  background-color: transparent;
  backface-visibility: hidden;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.cursor-clicked {
  z-index: 1000;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  backface-visibility: hidden;
}

.add-stress {
  z-index: 1000;
  position: absolute;
  transform: translate(42px, 0);
  pointer-events: none;
  backface-visibility: hidden;
}

.remove-stress {
  z-index: 1000;
  position: absolute;
  transform: translate(42px, 0);
  pointer-events: none;
  backface-visibility: hidden;
}

.cursor-clicked p {
  color: transparent;
  visibility: visible;
  pointer-events: none;
  backface-visibility: hidden;
  color: #fff;
  animation: bubbletext 1s ease;
  animation-fill-mode: forwards;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.add-stress div {
  visibility: visible;
  pointer-events: none;
  color: var(--red);
  animation: bubbletext 2s ease;
  animation-fill-mode: forwards;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.remove-stress div {
  visibility: visible;
  pointer-events: none;
  color: var(--green);
  animation: bubbletext 2s ease;
  animation-fill-mode: forwards;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

@keyframes bubbletext {
  from {
    font-size: 0rem;
  }

  30% {
    font-size: 2rem;
    opacity: 1;
  }

  to {
    font-size: 2rem;
    opacity: 0;
  }
}

.bubble {
  position: absolute;
  z-index: 1000;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: 2px solid #fff;
  animation: bubbleplop 1s ease;
  animation-fill-mode: forwards;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}

@keyframes bubbleplop {
  from {
    width: 0%;
    height: 0%;
  }

  30% {
    width: 7%;
    height: 10%;
    opacity: 1;
  }

  to {
    width: 10%;
    height: 15%;
    opacity: 0;
  }
}

.Skip-Message {
  width: 5%;
  height: 5%;
  bottom: 0%;
  right: 0%;
  z-index: 100;
  position: absolute;
}

@keyframes getOut {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    color: transparent;
    font-size: 0px;
    transform: translateY(0%);
  }

  100% {
    font-size: 40px;
    transform: translateY(-100%);
    color: transparent;
  }
}
