.Tablet {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fobjects%2Ftablette-zoom-vide.svg?alt=media&token=6d804d7a-a70a-4e95-809c-69490185bd77);
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: relative;
  background-position: center;
  background-size: contain;
}

.Tablet_content {
  display: flex;
  align-items: center;
  position: absolute;
  width: 72.7%;
  height: 86%;
  left: 50%;
  top: 7%;
  transform: translateX(-50%);
  overflow: hidden;
}

.Tablet_content-left {
  flex: 2.5 1;
  padding: 2vw;
}

.Tablet_content-right {
  flex: 1 1;
}

.Tablet p,
.Tablet h3,
.Tablet li,
.Tablet h4 {
  color: #797877 !important;
}
.Tablet h3 {
  font-size: 2vw !important;
}
.Tablet h4 {
  font-size: 1.4vw !important;
  margin: 1vw 0vw;
}

.Tablet p,
.Tablet li {
  font-size: 90%;
}

.Tablet_content-right {
  background-color: #e2d9da;
  margin: 0 1vw;
  border-radius: 2vw;
  padding: 1vw;
}

.Tablet_content-right-patient {
  background-color: #ffffff;
  border-radius: 2vw;
  display: flex;
  align-items: center;
  margin-top: 1vw;
}

.Tablet_content-right-patient img {
  height: 5vw;
  width: 5vw;
}

.Tablet_content-right-patient-infos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5%;
}

.Tablet_content-right-patient-info {
  padding: 2.5%;
}

.Tablet_content-right-patient-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Tablet_content-right-patient-description,
.Tablet_content-left-harnais-description {
  text-align: left;
  padding-inline-start: 2vw;
  margin-block-start: 1vw;
  margin-block-end: 1vw;
}

.Tablet_content-left-list {
  display: flex;
  border-radius: 20px;
}

.Tablet_content-left-harnais {
  border-radius: 2vw;
  margin: 1vw;
  text-decoration: none;
  border: none;
  background-color: transparent;
  height: 100%;
}

.Tablet_content-left-harnais-title {
  border-top-left-radius: 2vw;
  border-top-right-radius: 2vw;
  padding: 1vw;
  width: 100%;
}

.Tablet_content-left-harnais-title p {
  font-weight: bold !important;
}

.Tablet_content-left-harnais-descriptions {
  text-align: left;
  font-size: small;
  background-color: #ffffff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.Tablet_content-left-harnais-descriptions img {
  height: 10vw;
}

.Tablet_content-left-error {
  margin: 1vw;
}

.Tablet_content-left-error-text {
  color: #585856;
  position: absolute;
  bottom: 27%;
}

.Tablet_drop-down section {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Tablet_drop-down section:hover {
  box-shadow: 4px 4px 50px rgba(#687bf7, 0.6);
}

.Tablet_drop-down ul {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.Tablet_drop-down li {
  display: flex;
  align-items: center;
  cursor: move;
  line-height: 1;
  margin-bottom: 0.1em;
  border-radius: 20px !important;
  padding: 2.5%;
  background-color: #efeeee;
  box-shadow: 2.5px 2.5px 2.5px grey;
  margin-bottom: 2.5%;
}

.Tablet_drop-down li .dropArea {
  color: white !important;
  background: white !important;
  position: relative;
}

.Tablet_drop-down li .dropArea::before {
  content: 'Drop Here';
  color: #687bf7;
  font-size: 0.5em;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  border: 2px dashed #687bf7;
  border-radius: 3px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Tablet_drop-down li .dropArea span {
  display: none;
}

.Tablet_drop-down li .dropArea p {
  margin-left: 1em;
}

.Tablet_drop-down li:hover {
  background: linear-gradient(to left, #aa5a9f, #e1867b);
  color: white;
}

.Tablet_drop-down li:hover span {
  background-color: #79e7eb;
}

.Tablet_drop-down li:hover p {
  color: white !important;
}

.Tablet_drop-down li span {
  display: inline-block;
  margin-right: 1em;
  margin-left: 0.5em;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: white;
  font-weight: bold;
  transition: background-color 100ms linear;
  border-radius: 20px;
  background: linear-gradient(to left, #aa5a9f, #e1867b);
}

.Tablet_drop-down li p {
  transition: margin-left 50ms ease-in-out;
  text-align: left;
}

.Tablet_drop-down li i {
  margin-left: auto;
  margin-right: 1em;
}

@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.Tablet_content-left-img {
  width: 100%;
}

.Tablet_content-drop-item {
  flex: 1 1;
}

.Tablet_content-drop-text {
  flex: 4.5 1;
}

.Tablet_content-drop-arrow {
  flex: 0.5 1;
}
