.Inventory-Container {
  position: fixed;
  color: #fff;
  font-size: 1.5rem;
  z-index: 1;
  width: 40%;
  height: 10vh;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0px 30px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  border-radius: 0 0 50px 0;
}
.Inventory-Items {
  width: 80%;
  height: 10vh;
  display: flex;
}
.Inventory-Object {
  width: calc(100% / 6);
  display: flex;
  align-items: center;
}

.Inventory-Name {
  font-size: 1.1vw;
}

.Inventory-Object img {
  height: 70%;
  margin: 0px 15px;
  animation: bounceInventory 2s ease infinite;
}
@keyframes bounceInventory {
  from {
    transform: translateY(0px) scale(0.8) rotate(15deg);
  }
  50% {
    transform: translateY(5px) scale(0.8) rotate(15deg);
  }
  to {
    transform: translateY(0px) scale(0.8) rotate(15deg);
  }
}
.Inventory-Object img:hover {
  animation: scaleInventory 0.3s ease;
  animation-fill-mode: forwards;
}
@keyframes scaleInventory {
  from {
    transform: scale(0.8) translateY(0px);
  }
  to {
    transform: scale(1) translateY(0px);
  }
}

.Inventory-Object-Select {
  display: flex;
  align-items: center;
}
.Inventory-Object-Select img {
  height: 70%;
  margin: 0px 15px;
  animation: bounceInventory 2s ease infinite;
}

.Hud-Container {
  right: 0%;
  color: #fff;
  z-index: 1;
  height: 10vh;
  width: 38%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  top: 0;
  position: absolute;
}

.Hud-Container-Anim {
  position: fixed;
  right: 0%;
  color: #fff;
  z-index: 20;
  width: 30vw;
  height: 10vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Hud-Item-Image-time {
  width: 3vw;
  height: 3vw;
  position: absolute;
  right: 6%;
}

.Hud-Item-Image-score {
  transform: scale(1);
  animation: rotateMe 15s linear infinite;
}

.Hud-Item-Image-Anim-score {
  width: 3vw;
  position: absolute;
  right: 6%;
  transform: scale(1);
  animation: bounceIt 1.5s infinite;
}

.Hud-Item-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9vw;
  position: relative;
}

.Hud-Item-Container-sound,
.Hud-Item-Container-music {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5vw;
  position: relative;
}

.Hud-Item-Text-time {
  display: flex;
  align-items: center;
  width: 70%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding-left: 10%;
  backdrop-filter: blur(3px);
  height: 5vh;
}

.Hud-Item-Text-click {
  display: flex;
  align-items: center;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding-left: 10%;
  backdrop-filter: blur(3px);
  height: 5vh;
}

.Hud-Item-Text-score {
  display: flex;
  align-items: center;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding-left: 10%;
  backdrop-filter: blur(3px);
  height: 5vh;
}

.Hud-Item-Text-sound,
.Hud-Item-Text-music {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  backdrop-filter: blur(3px);
  height: 6vh;
}

.Hud-Item-Text-time p,
.Hud-Item-Text-score p,
.Hud-Item-Text-click p {
  font-weight: 700;
  font-size: 0.9vw;
}

.Hud-Item-Image-Content,
.Hud-Item-Image-score,
.Hud-Item-Image-click,
.Hud-Item-Image-time {
  position: absolute;
  width: 2vw;
  right: 0%;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
}

.Hud-Item-Image-sound,
.Hud-Item-Image-music {
  width: 2.5vw;
}

.Hud-Item-Image-Content-Tour {
  position: absolute;
  width: 10vw;
  left: 20%;
  top: 28%;
  z-index: 1;
}

.Hud-Item-Mute {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5vw;
  position: relative;
}
.Hud-Item-Image-Mute {
  display: flex;
  align-items: center;
  width: 70%;
  height: 5vh;
}

.Hud-Item-time-Tour::before {
  position: absolute;
  content: '';
  height: 14%;
  width: 2%;
  background: black;
  top: 50%;
  left: calc(50% - 2%);
  border-radius: 5px;
  animation: spin 6s linear infinite;
  transform-origin: top;
  z-index: 2;
}

.Hud-Item-time-Tour::after {
  position: absolute;
  content: '';
  height: 9%;
  width: 2%;
  background: black;
  top: 50%;
  left: calc(50% - 2%);
  border-radius: 5px;
  animation: spin 72s linear infinite;
  transform-origin: top;
  z-index: 2;
}

.Hud-Item-time-content-Tour::before {
  content: '';
  position: absolute;
  width: 4%;
  height: 4%;
  border-radius: 50%;
  background: #ff045c;
  left: 49%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.Hud-Tour-Clock {
  border: 1px solid red;
  width: 200px;
  height: 200px;
}

/* Big */
.Hud-Item-time::before {
  position: absolute;
  content: '';
  height: 32%;
  width: 5%;
  background: black;
  top: 50%;
  left: calc(50% - 2%);
  border-radius: 5px;
  animation: spin 6s linear infinite;
  transform-origin: top;
  z-index: 2;
}

.Hud-Item-time::after {
  position: absolute;
  content: '';
  height: 22%;
  width: 5%;
  background: black;
  top: 50%;
  left: calc(50% - 2%);
  border-radius: 5px;
  animation: spin 72s linear infinite;
  transform-origin: top;
  z-index: 2;
}

.Hud-Item-time-stop::before {
  position: absolute;
  content: '';
  height: 32%;
  width: 5%;
  background: black;
  top: 50%;
  left: calc(50% - 2%);
  border-radius: 5px;
  transform-origin: top;
  z-index: 2;
}

.Hud-Item-time-stop::after {
  position: absolute;
  content: '';
  height: 22%;
  width: 5%;
  background: black;
  top: 50%;
  left: calc(50% - 2%);
  border-radius: 5px;
  transform-origin: top;
  z-index: 2;
}

@keyframes spin {
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.Hud-Item-time-content::before {
  content: '';
  position: absolute;
  width: 9%;
  height: 9%;
  border-radius: 50%;
  background: #ff045c;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@keyframes rotateMe {
  from {
    transform: rotateY(0deg) translateY(-50%);
  }
  10% {
    transform: rotateY(180deg) translateY(-50%);
  }
  to {
    transform: rotateY(180deg) translateY(-50%);
  }
}

@keyframes bounceIt {
  from {
    transform: scale(1);
  }
  10% {
    transform: scale(1.2);
  }
  20% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
