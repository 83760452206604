.Computer {
  background-repeat: no-repeat;
  height: 90vh;
  position: relative;
  width: 100vw;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  border: none;
  background-color: transparent;
}

.Computer.off {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FBreakRoom%2Fobjects%2Frest%2Fordi-eteint.svg?alt=media&token=96c965ce-b618-4d3f-8aff-f27497e9d06d);
}

.Computer.on {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FBreakRoom%2Fobjects%2Frest%2Fordi-allume.svg?alt=media&token=f0bd48e2-29a9-4150-abd7-2966c64fc915);
}

.Icons_Computer {
  display: flex;
}

.Computer_content {
  display: flex;
}

.Computer_content-left {
  display: flex;
  flex-direction: column;
}

.Computer_content-left-button {
  position: relative;
  justify-content: space-around;
  display: flex;
  background-color: transparent;
  border: none;
  margin-top: 6vh;
  margin-left: 22vw;
}

.Computer_content-right-screen {
  display: flex;
}

.Computer_content-right-screen img {
  position: absolute;
  align-items: center;
  margin-top: 2vh;
  margin-left: 2vw;
  height: 64vh;
  width: 41vw;
}

.Computer_content-right-screen button {
  position: relative;
  z-index: 6;
  height: 3vh;
  width: 12vw;
  margin-top: 49vh;
  margin-left: 16.1vw;
  border-radius: 10px;
  background-color: #77b55a;
  border-radius: 6px;
  border: 2px solid #4b8f29;
  display: inline-block;
  color: #ffffff;
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  text-shadow: 0px 2px 0px #5b8a3c;
}
