.ArcadeRoom-img {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.ArcadeRoom-img-div {
  position: relative;
  display: flex;
  height: 100vh;
  width: calc((8 / 3) * 100vh);
}

.ArcadeRoom-img-div-center {
  transform: translateX(calc((-8 / 3) * 50vh + 50vw));
}
.ArcadeRoom-img-div-right-from-center {
  animation: goRightFromCenterVac 1s ease forwards;
}
.ArcadeRoom-img-div-center-from-left {
  animation: goCenterFromLeftVac 1s ease forwards;
}
.ArcadeRoom-img-div-left-from-center {
  animation: goLeftFromCenterVac 1s ease forwards;
}
.ArcadeRoom-img-div-center-from-right {
  animation: goCenterFromRightVac 1s ease forwards;
}

@keyframes goRightFromCenterVac {
  from {
    transform: translateX(calc((-8 / 3) * 50vh + 50vw));
  }
  to {
    transform: translateX(calc((-8 / 3) * 100vh + 100vw));
  }
}
@keyframes goCenterFromLeftVac {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc((-8 / 3) * 50vh + 50vw));
  }
}
@keyframes goLeftFromCenterVac {
  from {
    transform: translateX(calc((-8 / 3) * 50vh + 50vw));
  }
  to {
    transform: translateX(0);
  }
}
@keyframes goCenterFromRightVac {
  from {
    transform: translateX(calc((-8 / 3) * 100vh + 100vw));
  }
  to {
    transform: translateX(calc((-8 / 3) * 50vh + 50vw));
  }
}

.changeViewRight {
  position: absolute;
  top: 35%;
  height: 30%;
  width: 2%;
  right: 2%;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(7px);
  border: solid 1px #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.changeViewLeft {
  position: absolute;
  top: 35%;
  height: 30%;
  width: 2%;
  left: 2%;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(7px);
  border: solid 1px #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.changeViewRight:hover,
.changeViewLeft:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.changeViewRight:active,
.changeViewLeft:active {
  background-color: rgba(255, 255, 255, 0.6);
}

.changeViewLeftOpacity1,
.changeViewRightOpacity1 {
  animation: opacityOn 0.5s ease forwards;
  cursor: pointer;
}
.changeViewLeftOpacity0,
.changeViewRightOpacity0 {
  animation: opacityOff 0.5s ease forwards;
  cursor: auto;
}
@keyframes opacityOn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes opacityOff {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.changeViewLeftOpacity1 img,
.changeViewRightOpacity1 img,
.changeViewLeftOpacity0 img,
.changeViewRightOpacity0 img {
  width: 2vw;
}

.changeViewDesk {
  position: absolute;
  top: 5%;
  z-index: 5;
  height: 5%;
  font-size: 1vw;
  left: 50%;
  color: #fff;
  transform: translateX(-50%) translateY(-50%);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 15px;
  backdrop-filter: blur(7px);
  border: solid 1px #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.changeViewDesk:hover,
.changeViewDeskDispenser:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.changeViewDesk:active,
.changeViewDeskDispenser:active {
  background-color: rgba(255, 255, 255, 0.6);
}

.changeViewDeskDispenser {
  position: absolute;
  top: 50%;
  z-index: 5;
  height: 5%;
  font-size: 1vw;
  right: 0%;
  color: #fff;
  transform: translateX(-50%) translateY(-50%);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 15px;
  backdrop-filter: blur(7px);
  border: solid 1px #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.changeViewDeskFloppy {
  position: absolute;
  top: 5%;
  height: 5%;
  left: 50%;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(7px);
  border: solid 2px #fff;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  animation: bouncechangeViewRoomFloppy 1s ease infinite;
}
.changeViewDeskFloppy:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.changeViewDeskFloppy:active {
  background-color: rgba(255, 255, 255, 0.6);
}
@keyframes bouncechangeViewRoomFloppy {
  from {
    transform: translateX(-50%) translateY(-50%) scale(0.9);
  }
  50% {
    transform: translateX(-50%) translateY(-50%) scale(1);
  }
  to {
    transform: translateX(-50%) translateY(-50%) scale(0.9);
  }
}

.ArcadeRoom-img-div:focus {
  outline: none;
}

.ArcadeRoom-TrickyNeon {
  position: absolute;
  z-index: 5;
  width: 19%;
  right: 0;
  top: 0;
  animation: fadeNeon 6s ease infinite;
}
.ArcadeRoom-TrickyNeonDark {
  position: absolute;
  z-index: 4;
  width: 11.4%;
  right: 3.5%;
  top: 3.3%;
}

.ArcadeRoom-borneBack {
  position: absolute;
  z-index: 5;
  width: 16%;
  left: 0;
  bottom: 0;
}

.ArcadeRoom-sofa {
  position: absolute;
  z-index: 4;
  width: 16.6%;
  left: 5%;
  bottom: 20%;
}

.ArcadeRoom-plant {
  position: absolute;
  z-index: 3;
  width: 8%;
  left: 16%;
  top: 18.5%;
}

.ArcadeRoom-greenArcade {
  position: absolute;
  z-index: 8;
  height: 38.4%;
  right: 0;
  top: 19%;
}

.ArcadeRoom-yellowArcade {
  position: absolute;
  z-index: 6;
  height: 32%;
  right: 8.8%;
  top: 20.9%;
}

.ArcadeRoom-redArcade {
  position: absolute;
  z-index: 5;
  height: 43.4%;
  left: 39.7%;
  top: 21%;
}

.ArcadeRoom-yellowArcade2 {
  position: absolute;
  z-index: 3;
  height: 22%;
  left: 36.2%;
  top: 22.8%;
}

.ArcadeRoom-blueArcade {
  position: absolute;
  z-index: 5;
  height: 59%;
  right: 29%;
  top: 17.3%;
  pointer-events: none;
}

@keyframes fadeNeon {
  from {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  41% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  46% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  81% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

.cursor-clickedVacc {
  z-index: 10;
  position: absolute;
  transform: translate(-50%, -40%);
  pointer-events: none;
  backface-visibility: hidden;
}

.cursor-clickedVacc p {
  color: transparent;
  visibility: visible;
  pointer-events: none;
  backface-visibility: hidden;
  color: #fff;
  transform: translate(-10%, -25%);
  animation: bubbletext404 1s ease;
  animation-fill-mode: forwards;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

@keyframes bubbletext404 {
  from {
    font-size: 0rem;
  }
  30% {
    font-size: 1.5rem;
    opacity: 1;
  }
  to {
    font-size: 2rem;
    opacity: 0;
  }
}

.bubbleVacc {
  position: absolute;
  z-index: 1000;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: 2px solid #fff;
  animation: bubbleplop404 1s ease;
  animation-fill-mode: forwards;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}

@keyframes bubbleplop404 {
  from {
    width: 0px;
    height: 0px;
  }
  30% {
    width: 50px;
    height: 50px;
    opacity: 1;
  }
  to {
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}

.Loader-Vaccination-Container {
  height: 100vh;
  background-color: #2d1837;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Loader-Computer-Container {
  width: 70vw;
  height: 60vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Loader-Container {
  height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Loader-Computer {
  position: absolute;
  top: 0%;
  width: 35vw;
  z-index: 1;
}

.Loader-Computer-Text h1 {
  font-weight: 700;
  color: #fff;
}

.Loader-Screen-Saver {
  position: absolute;
  width: 23vw;
  top: 16%;
  left: 37%;
}

.Loader-Progress-Bar-Container {
  height: 2vh;
  width: 30vw;
  background-color: #e0e0de;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
}

.Loader-Progress-Bar {
  height: 100%;
  background-color: #552d88;
  border-radius: inherit;
  transition: width 0.5s ease-in-out;
  display: flex;
  align-items: center;
}

.Loader-Progress-Bar-Text {
  padding: 20px 5px;
  color: white;
  text-align: center;
  font-weight: 700;
}

.Loader-Progress-Bar-Loading {
  margin-top: 2%;
  color: #fff;
  font-weight: 700;
  text-align: center;
  font-size: 1rem;
}

.click-through {
  pointer-events: none;
}
