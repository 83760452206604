.evaluation {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  .blur {
    position: absolute;
    filter: blur(2px);
    z-index: 1;
    width: 100%;
    height: 100%;
    animation: blur 0.2s ease;
    animation-fill-mode: forwards;
  }
  .container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 80%;
    height: 100%;
    z-index: 3;
    left: 2%;
    p {
      color: white;
    }
  }
}
