.img-background {
  filter: brightness(0.5);
}

.background,
.loading {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20%;
  backdrop-filter: blur(7px);

  p {
    font-size: larger;
    font-weight: bold;
    background-color: #eaeaea;
    border-radius: 5px;
    padding: 1rem;
  }
}
.loadingBox {
  height: 40%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
