ol {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

.crossword {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  margin-top: 30px;
}

.boxes {
  display: flex;
  flex-wrap: wrap;
  width: calc(30px * 13);
}

.clue {
  padding: 5px 0;
}

.box {
  width: 30px;
  height: 30px;
  text-align: center;
}

.box.isValid {
  background-color: #38833894;
}

.boxYellow {
  border: 3px solid #ffa217;
}

.box-wrapper {
  position: relative;
}

.box-label {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: xx-small;
}

.box.isNotMainUser {
  cursor: url('../../../../../../img/unavailable.cur'), default;
}

.blank {
  background: transparent;
}

.solved {
  background: rgba(46, 139, 87, 0.8);
}

@media all and (max-width: 1030px) {
  .crossword {
    flex-direction: column;
  }

  .clue-lists {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
}
