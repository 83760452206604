.drawerMorse-image,
.drawerMorse {
  height: 100%;
}

.drawerMorse {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.drawerMorse.Modal_description {
  display: flex;
  align-items: center;
}

.drawerMorse-image {
  position: absolute;
  height: 30%;
  z-index: 10;
  margin-top: 10%;
}

.drawerMorse-image img {
  height: 30%;
}

.drawerMorse-inputs {
  position: absolute;
  margin-top: 18%;
  z-index: 11;
  display: flex;
}

.drawerMorse-inputs input {
  height: 50px;
  margin: 0 6px 0 15px;
  width: 50px;
  background-color: #c66577;
  border: none;
  color: white;
  text-align: center;
  border-radius: 2px;
}

/* Disabled arrow on input */
/* Chrome, Safari, Edge, Opera */
.drawerMorse-inputs input::-webkit-outer-spin-button,
.drawerMorse-inputs input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.drawerMorse-inputs input[type='number'] {
  -moz-appearance: textfield;
}
