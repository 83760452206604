// Mixin to turn the opacity on/off
@mixin opacitySwitch($name, $start, $end) {
  animation-name: $name;

  @at-root {
    @keyframes #{$name} {
      from {
        opacity: $start;
      }
      to {
        opacity: $end;
      }
    }
  }
}

// Mixin for media queries
@mixin maxMediaQuery($max) {
  @media (max-width: $max) {
    @content;
  }
}
