.side-panel {
  position: relative;
  > img {
    width: 100%;
  }
}

.side-content {
  position: absolute;
  bottom: 0;
  width: 96%;
  height: 94%;
  left: 1.5rem;
  display: grid;
  grid-template-rows: 0.25fr repeat(3, 1fr);
  grid-template-columns: 0.65fr 1.2fr 0.7fr;
  align-items: center;
  justify-items: center;

  div {
    width: 96%;
    height: 94%;
    position: absolute;
  }

  svg {
    grid-area: 2/1/5/4;
    margin: 0 0.5rem 0 1rem;
    padding: 1rem;
  }

  p {
    margin: 0;
    font-size: 1.25rem;
    color: white;
    grid-area: 1/2/2/3;
  }
}
