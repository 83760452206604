.choice {
  color: white;
  font-size: x-large;
  background-color: rgba(0, 0, 0, 0.2901960784);
  border-radius: 10px;
  padding: 5px 30px;
  width: 80%;
  position: absolute;
  top: 10%;
  left: 5%;
  .list-response {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    button {
      padding: 0.3vw;
      font-size: smaller;
      margin: 15px 10px;
      border-width: 3px;
    }
  }
  .answer {
    background-image: linear-gradient(
      to bottom right,
      rgba(219, 219, 219, 0.5),
      rgba(53, 55, 87, 0.8)
    );
    padding: 10px;
    border-radius: 10px;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
