.lightBackground {
  position: absolute;
  top: -2%;
  right: -5%;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.light {
  position: absolute;
  z-index: -100;
  margin: -29% 0 0 0%;
}
