.History {
  position: absolute;
  width: 66%;
  left: 50%;
  transform: translateX(-69%) translateY(-52%);
  height: 31.8vw;
  top: 49.5%;
  border-radius: 6%;
  z-index: 5;
}

.History_top {
  display: flex;
  position: absolute;
  top: 35%;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
}
.History_bottom {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 65%;
  width: 36vw;
  left: 7%;
}

.History_bottom-top p {
  font-size: 1.6vw;
}

.History_bottom-bottom {
  display: flex;
  justify-content: flex-end;
}

.History_top-left,
.History_top-right {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.History_top p {
  font-size: 1.6vw;
  margin-bottom: 1vw !important;
}

.History_top .react-date-picker {
  height: 4vw;
  text-align: center;
  font-size: 2vw;
}

.History_top-right {
  margin-left: 5%;
}

.History_top-right p:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  border: thin solid gray;
  border: 1px solid white;
  border-radius: 1vw;
  background-color: #c2cbd5b5;
  color: white;
  font-weight: bold;
  height: 4vw;
  font-size: 3vw;
}

.History .react-date-picker__wrapper {
  display: flex;
  border: thin solid gray;
  border: 1px solid white;
  border-radius: 1vw;
  background-color: #c2cbd5b5;
  color: white;
}

.History .react-date-picker__inputGroup input {
  color: white;
}

.History .react-date-picker__clear-button svg,
.History .react-date-picker__calendar-button svg,
.History .react-date-picker__button:enabled:hover {
  stroke: white !important;
}
