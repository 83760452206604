.timer-tv {
  grid-area: 2 / 2 / 3 / 3;
  width: 100%;
  margin-left: 0.5rem;
}

.timer-tv .react-date-picker {
  width: 100%;
}

.timer-tv .react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
  border: 1px solid white;
  border-radius: 20px;
  background-color: #c2cbd5b5;
  color: white;
  padding: 0 0.5rem;
}

.timer-tv .react-date-picker__inputGroup input {
  color: white;
  font-size: 1.5rem;
}

.timer-tv .react-date-picker__clear-button svg,
.timer-tv .react-date-picker__calendar-button svg,
.timer-tv .react-date-picker__button:enabled:hover {
  stroke: white !important;
}

.timer-tv-text {
  align-self: start;
  grid-area: 3 / 2 / 4 / 6;
  margin-left: 0.5rem;
  display: grid;
  grid-gap: 0.5rem 1rem;
  align-items: center;
}

.timer-tv-text p {
  font-size: 1.5rem;
  font-weight: bold !important;
}

.timer-tv-result-number {
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid #fff;
  border-radius: 10px;
  font-size: 1.1vw;
  color: #fff;
  padding: 5px;
  font-weight: bold;
  padding: 0.5rem 4rem;
}

.timer-tv-result-number:last-of-type {
  grid-area: 3 / 2 / 4/ 3;
}

.timer-tv-text-turn-off {
  grid-area: 3 / 1 / 4/ 2;
}

.timer-tv-text button {
  width: fit-content;
  margin: 0;
  font-size: 1rem;
}

@media screen and (max-width: 1800px) {
  .timer-tv-text p {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 1100px) {
  .timer-tv-text p {
    font-size: 1rem;
  }
}
