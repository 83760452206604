.Door_image,
.Door {
  height: 100%;
}

.Door_content {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fchambres%2Fbackground-tablette.svg?alt=media&token=a1bcf853-2573-4b7d-be69-c73e7c002e69);
  background-repeat: round;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: flex-end;
}

.Door_content-inventory {
  display: flex;
  justify-content: flex-end;
  top: 18%;
  right: 5%;
  width: 25%;
  position: absolute;
  background-color: #cbd1df;
  align-items: center;
  flex-direction: column;
  border-radius: 1vw;
}

.Door_content-container {
  width: 78.5%;
  border-radius: 2vw;
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 50%;
  left: 50%;
  height: 92.6%;
  transform: translateX(-50%) translateY(-50%);
  align-items: center;
  justify-content: center;
}

.Door_content-text-background {
  width: 78.5%;
  background-color: #000000c7;
  border-radius: 2vw;
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 50%;
  left: 50%;
  height: 92.6%;
  transform: translateX(-50%) translateY(-50%);
  align-items: center;
  justify-content: center;
}

.Door_content-text {
  color: aliceblue;
  position: auto;
  justify-content: center;
  font-size: 6vw;
}

.Door_content-text-background img {
  width: 15vw;
  margin-bottom: 2vw;
}

.Door_content-text h1 {
  font-size: 2vh;
  margin: 0 5%;
}

.Door_content-inventory-content {
  background-color: #cbd1df;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 1vw;
}

.Door_content-inventory p {
  color: black !important;
  font-size: 1.2vw;
}
.Door_content-inventory h2 {
  color: black !important;
  font-size: 1.4vw;
  font-weight: bold;
  margin: 1vw;
}
.Door_content-inventory img {
  height: 50px;
  width: 50px;
}
.Door_content-inventory-content-category {
  border-top: 1px solid black;
  width: 100%;
  padding: 0.8vw;
}

.Door_content-inventory-content-category-title {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.Door_content-inventory-content-category-title p {
  flex: 2 1;
}

.Door_content-inventory-content-category-title button {
  height: 2vw;
  width: 2vw;
  font-size: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.Door_content-inventory-content-category-elements {
  display: flex;
  flex-wrap: wrap;
}

.Door_content-inventory-content-category-element {
  flex: 1 1;
  border: 1px solid grey;
  background-color: transparent;
  border-radius: 1vw;
  margin: 0.3vw;
  padding: 0.1vw;
}

.Door_content-inventory-content-category-element.--active {
  background-color: grey;
}

.Door_content-inventory-content-category-element:hover {
  border: 1px solid black;
}

.Door_content-inventory-content-category-element img {
  height: 3vw;
  width: 3vw;
  flex: 1 1;
}

.Door_content-object {
  position: absolute;
}

.Door_content-toolbar {
  width: 100%;
}

.Door_content-resident {
  display: flex;
  display: flex;
  bottom: 8%;
  left: 6%;
  position: absolute;
  width: 65%;
}

.Door_content-resident-button {
  text-decoration: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.Door_content-resident-button img {
  height: 7vw;
  width: 7vw;
}

.Door_content-resident-info {
  background-color: white;
  border-radius: 1vw;
  padding: 1vw;
  position: absolute;
  width: 80%;
  left: 15%;
  bottom: 0;
}

.Door_content-resident-info p {
  color: black !important;
  font-size: 1vw;
}

.Door_content-inventory-content-category-element-disponibility {
  background-color: white;
  border-radius: 1vw;
  height: 1.1vw;
  width: 1.1vw;
  text-align: center;
  display: flex;
  font-size: 1vw !important;
  align-items: center;
  justify-content: center;
}

.Door_content-inventory-content-category-element-image,
.Door_content-inventory-content-category-element-text {
  flex: 1 1;
  display: flex;
  justify-content: center;
  margin: 2%;
}

.Door_content-Toadd {
  position: absolute;
  top: 18%;
  right: 30%;
  background: #cbd1df;
  border-radius: 1vw;
  z-index: 6;
  box-shadow: 0px 0px 1vw #878787;
  width: 25%;
}
.Door_content-Toadd img {
  width: 6vw;
  padding: 1vw;
}
.Door_content-Toadd p {
  color: #000 !important;
  margin: 2% 5% !important;
}
.Door_content-Toadd-Buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
