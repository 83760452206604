.wrapper {
  width: 50%;
  z-index: 11;
  .main-screen {
    right: 2vw;
    position: absolute;
    top: 10vh;
    border-radius: 10px;
  }

  .footer {
    height: 10vh;
  }
}
