.Health {
  padding: 5px;
}

.Health p {
  color: #272929 !important;
}

.Health_firstTitle {
  text-align: center;
}

.Health_secondTitle {
  margin: 10px 0 !important;
}

.Health_secondTitle,
.Health_botton-contents-subtitle {
  font-size: smaller;
}

.Health_time,
.Health_activity-pourcent {
  font-size: xx-large;
}

.Health_title {
  font-weight: 600 !important;
}

.Health_content {
  background-color: #f5a981;
  border-radius: 10px;
  padding: 5px;
  font-size: 12px;
}

.Health_bar {
  height: 1px;
  width: 100%;
  background-color: #e3e3e3;
  margin-top: 20px;
}

.Health_bottom-contents {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px !important;
}

.Health_bottom-contents-graph {
  width: 30%;
}

.Health_botton-contents-description {
  margin-left: 20px;
}

.Health_botton-contents-subtitle {
  margin: 5px 0 !important;
}

.Health_activities {
  display: flex;
  width: 100%;
}

.Health_activity {
  background-color: #f5a981;
  border-radius: 20px;
  flex: 1 1;
  padding: 5%;
  margin: 5% 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  max-width: 30%;
  min-width: 30%;
}

.Health_activity p {
  margin: 5% 0 !important;
}
