.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 55%;
  width: 55%;
}

.modal {
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    overflow: auto;
  }
}

#object-text {
  color: black;
  font-weight: 500;
  text-align: center;
  font-size: 1.5rem;
  margin-top: 1rem;
}
