.phone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;

  .enigma {
    position: absolute;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    height: 520px;
    width: 295px;
    border-radius: 50px;
    backdrop-filter: blur(10px);
    border: 4px solid #d8d9d8;

    .bar {
      background-color: #272929;
      height: 4px;
      width: 43px;
      border-radius: 10px;
      margin: 1.5rem 0;
    }

    .lock {
      border-radius: 45px;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 30px;

      img {
        margin: 20px;
      }

      .codes {
        margin: 20px;

        .points {
          height: 10px;
          width: 10px;
          padding: 5px;
          margin: 5px;
          display: inline-block;
          font-size: inherit;
          text-rendering: auto;
          border: 1px solid;
          border-radius: 50%;
          color: #fff;
          text-align: center;
        }
        .background {
          background-color: rgb(255, 255, 255);
        }
      }

      .touchs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        button {
          border: none;
          font-size: 20px;
          margin: 5px;
          height: 50px;
          width: 50px;
          border-radius: 50%;
          background-color: transparent;
          border: 2px solid white;
          color: white;
        }

        button:hover {
          opacity: 0.8;
        }
      }
    }

    .screenContent {
      display: flex;
      flex-direction: column;
      height: 75%;
      width: 260px;
      .screenHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 0 1;
        margin: 10px 5px;
        color: white;
      }
      .buttons {
        margin: 0.75rem;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        flex: 3 1;

        .button {
          flex: 1 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          margin: 0.5rem 0;

          .app {
            border: none;
            padding: 15px;
            border-radius: 10px;
          }

          .violet {
            background-color: #5961a5;
          }

          .grey {
            background-color: #d5d4d8;
          }

          .white {
            background-color: #ffffff;
          }

          .greylight {
            background-color: #d5d5d2;
          }

          .bluelight {
            background-color: #31b5e9;
          }

          .greenlight {
            background-color: #74ba5b;
          }
          p {
            font-size: small;
            margin-top: 5px !important;
          }
        }
      }
    }
    .footer {
      flex: 1 1;
      .button {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 0.5rem 0;

        .app {
          border: none;
          padding: 15px;
          border-radius: 10px;
        }

        .white {
          background-color: #ffffff;
        }

        p {
          font-size: small;
          margin-top: 5px !important;
        }
      }
    }
    .pad {
      background-color: transparent;
      border: 1px solid white;
      height: 45px;
      width: 45px;
      border-radius: 15px;
      margin: 1rem;
    }
  }
  .enigmaLock {
    background: linear-gradient(
      180deg,
      #0057ff 48.23%,
      rgba(108, 140, 255, 0.8) 100%
    );
  }

  .enigmaUnlock {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fphone%2FACCUEIL_TELEPHONE.svg?alt=media&token=daf30af5-0962-496a-a1c1-832179d651c8);
  }
}
