.KitchenLivingRoom-img {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.KitchenLivingRoom-img-div {
  position: relative;
  display: flex;
  height: 100%;
  width: calc((8 / 3) * 102vh);
  padding: 0;
  border: 0;
  background-color: transparent;
}

.KitchenLivingRoom-img .ModalEnigma_description-contents {
  height: 100%;
  position: absolute;
  width: 100%;
  justify-content: center;
}

.KitchenLivingRoom-img header {
  display: flex;
  position: absolute;
  width: 100%;
}

.KitchenLivingRoom_object--isDisplay {
  position: absolute;
}

.KitchenLivingRoom_object--isNotDisplay,
.KitchenLivingRoom_object--isDisplayCode {
  position: absolute;
  opacity: 0;
}

.KitchenLivingRoom_object--isNotDisplay {
  display: none;
}

.KitchenLivingRoom_object--isDisplayCode:hover {
  position: absolute;
  opacity: 1;
}
