.Inventory {
  color: #fff;
  font-size: 1.5rem;
  z-index: 1;
  height: 10vh;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  border-radius: 0 0 15px 0;
  width: 39%;
}
.Inventory p {
  font-size: 1.3vw;
  margin-right: 15px;
}

.Inventory_object {
  display: flex;
}
.Inventory_object img {
  height: 7.5vh;
  width: 3.75vw;
  animation: bounceInventory 2s ease infinite;
}

@keyframes bounceInventory {
  from {
    transform: translateY(0px) scale(0.8);
  }
  50% {
    transform: translateY(5px) scale(0.8);
  }
  to {
    transform: translateY(0px) scale(0.8);
  }
}

.Inventory_object img:hover {
  animation: scaleInventory 0.3s ease;
  animation-fill-mode: forwards;
}
@keyframes scaleInventory {
  from {
    transform: scale(0.8) translateY(0px);
  }
  to {
    transform: scale(1) translateY(0px);
  }
}
