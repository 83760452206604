.ModalEnigma p {
  display: block;
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0px;
}

.ModalEnigma {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 13;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalEnigma_blur {
  position: absolute;
  filter: blur(2px);
  z-index: 1;
  width: 100%;
  height: 100%;
  animation: ModalEnigmaActionBlur 0.2s ease;
  animation-fill-mode: forwards;
}

.ModalEnigma_RPS {
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalEnigma_blur_RPS {
  position: absolute;
  filter: blur(0px);
  z-index: 1;
  width: 100%;
  height: 100%;
}

@keyframes ModalEnigmaActionBlur {
  from {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
}

.ModalEnigma_description {
  z-index: 3;
  flex-direction: column;
  height: 100%;
}
.ModalEnigma_description.--multi {
  width: 80%;
}

.ModalEnigma_description-contents {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.ModalEnigma_description-text {
  color: #fff;
}

.ModalEnigma_description-content {
  background-color: #e0615d;
  flex: 1 1;
  height: 100%;
  padding: 2%;
  margin-left: 2%;
}

.ModalEnigma_enigma {
  display: flex;
  align-items: center;
}
.ModalEnigma_description .ButtonAction.Return {
  position: absolute;
  bottom: 0%;
  right: 50%;
}

.CorridorTMS .ModalEnigma .ModalEnigma_description-contents,
.RestaurantTMS .ModalEnigma .ModalEnigma_description-contents {
  height: 90%;
}

#container .ModalEnigma .ModalEnigma_description-contents {
  height: 100%;
  justify-content: center;
}
