.InterfaceGame {
  position: absolute;
  top: 10vh;
  right: 0;
  height: 85%;
  display: flex;
  z-index: 1;
  width: 80%;
}

.InterfaceGame_gameover {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 10;
  /* Center the game over image and text */
  left: 25%;
  margin-left: -50px;
  top: 25%;
  margin-top: -50px;
}

.InterfaceGame_gameover img {
  /* Override the intrisic width defined in <img> to be relative to the parent size.
  The height will automatically be calculed with the aspect ratio  */
  width: 50%;
  height: auto;
}

.InterfaceGame_gameover p {
  width: 80%;
}

.InterfaceGame_button-information {
  height: 10%;
  margin-top: 5vh;
  background-color: #f2e4d3;
  border: none;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.InterfaceGame_button-information img {
  height: 45px;
  width: 45px;
}

.InterfaceGame_actions {
  /* Needed for game over to be over the blur */
  position: relative;
  background-color: #f2e4d3;
  border-radius: 5px;
  z-index: 6;
}

.blur {
  height: 100%;
  animation: modalActionBlur 0.2s ease;
  animation-fill-mode: forwards;
  background-color: #f2e4d3;
  border-radius: 5px;
  z-index: 6;
}

.InterfaceGame_informations {
  padding: 10px;
  margin-top: 20px;
  background-color: #f2e4d3;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.InterfaceGame_informations-closed {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  transition: 0.25s;
  transform: translateX(94%);
  z-index: 5;
  display: flex;
  height: 70%;
}

.InterfaceGame_informations-open {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  transition: 0.25s;
  transform: translateX(0%);
  display: flex;
  height: 70%;
}

.InterfaceGame_informations-open.blur {
  opacity: 0;
}

.InterfaceGame_informations-closed.blur {
  opacity: 0;
}

.InterfaceGame_actions-contents {
  display: flex;
}

.InterfaceGame_actions-contents,
.InterfaceGame_actions-helps,
.InterfaceGame_actions-contents-bottom {
  margin: 10px;
}

.InterfaceGame_actions-contents {
  margin-top: 30px;
}

.InterfaceGame_actions-helps {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.InterfaceGame_actions-help {
  border: none;
  text-decoration: none;
  padding: 5px 20px 10px 20px;
  border-radius: 5px;
  box-shadow: 5px 5px 5px 5px #cdc4b8;
  background-color: #ece9e9;
  color: #878787;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.InterfaceGame_actions-help-disabled {
  border: none;
  text-decoration: none;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #887676;
  background-color: transparent;
  color: #887676;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.InterfaceGame_actions-help:hover {
  background: linear-gradient(to right, #5096e8, #80d0c7);
  color: #ece9e9;
}

.InterfaceGame_actions-help-icons {
  position: absolute;
  margin-top: 45px;
}

.InterfaceGame_actions-help-icon {
  height: 10px;
  width: 10px;
}

.InterfaceGame_actions-help img,
.InterfaceGame_actions-help-disabled img {
  width: 30px;
  height: 30px;
}

.InterfaceGame_actions-contents-left {
  display: flex;
}

.InterfaceGame_actions-contents-left-top {
  box-shadow: 5px 5px 5px 5px #cdc4b8;
  border-radius: 5px;
  flex: 1 1;
}

.InterfaceGame_actions-contents-left-top-header {
  display: flex;
  background: linear-gradient(to right, #5096e8, #80d0c7);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px 10px 4px 0;
}

.InterfaceGame_actions-contents-left-top-header img {
  height: 80px;
  width: 80px;
  margin-top: 20px;
  position: absolute;
}

.InterfaceGame_actions-contents-left-top-contents {
  display: flex;
  flex-wrap: wrap;
  background-color: #f1e3d1;
  border-radius: 5px;
  margin: 20px 10px;
  justify-content: space-around;
}

.InterfaceGame_actions-contents-left-top-header p {
  margin-left: 65px;
  color: white;
  font-weight: bold;
}

.InterfaceGame_actions-contents-left-top-content {
  background-color: #fbf6f1;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  border-radius: 10px;
  color: #878787;
  align-items: center;
  min-width: 80px;
}

.InterfaceGame_actions-contents-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0.5 1;
  margin: 0 38px;
  color: #878787;
}

.InterfaceGame_actions-contents-right-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 21px;
  box-shadow: 0px 0 24px 1px rgb(0 0 0 / 30%) inset;
}

.InterfaceGame_actions-contents-right-outer {
  display: flex;
  align-items: flex-end;
  height: 228px;
  width: 40px;
  background: #fdf7e6;
  border: none;
  border-radius: 10px;
}

.InterfaceGame_actions-contents-right-inner {
  height: 250px;
  width: 40px;
  background: #55acdb;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  transition: width 100ms ease-out;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InterfaceGame_actions-contents-right-inner-rounded {
  height: 250px;
  width: 40px;
  background: #55acdb;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  transition: width 100ms ease-out;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InterfaceGame_actions-contents-right-inner-label {
  color: white;
}

.InterfaceGame_actions-progressionBar {
  width: 250px;
  height: 15px;
  background: #55acdb;
  border-radius: 25px;
  transition: height 100ms ease-out;
  border: none;
}

.InterfaceGame_actions-contents-bottom {
  display: flex;
  flex-direction: column;
}

.InterfaceGame_actions-contents-bottom-button {
  text-decoration: none;
  border: none;
  box-shadow: 5px 5px 5px 5px #cdc4b8;
  background-color: #f7f1ea;
  margin: 5px 0;
  padding: 10px;
  flex: 1 1;
  color: #878787;
  border-radius: 5px;
  text-align: left;
}

.InterfaceGame_actions-contents-bottom-button.red {
  border: 2px solid red;
  animation: shake-1 2s ease infinite;
}

@keyframes shake-1 {
  0% {
    transform: translate(1px, -2px) rotate(-1deg);
  }

  100% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}

.InterfaceGame_actions-contents-bottom-button-disabled {
  text-decoration: none;
  border: none;
  box-shadow: 5px 5px 5px 5px #cdc4b8;
  background-color: #f7f1ea;
  margin: 5px 0;
  padding: 10px;
  flex: 1 1;
  color: #878787;
  border-radius: 5px;
  text-align: left;
  opacity: 0.3;
}

.InterfaceGame_actions-contents-bottom-button:hover {
  background: linear-gradient(to right, #5096e8, #80d0c7);
  color: white;
}

/* MENU INFORMATION */

.InterfaceGame_informations-menu {
  display: flex;
  justify-content: space-around;
  padding: 10px 20px;
  border-radius: 21px;
  box-shadow: 0px 0 24px 1px rgb(0 0 0 / 30%) inset;
  width: 100%;
}

.InterfaceGame_informations-menu-button {
  text-decoration: none;
  border: none;
  box-shadow: 5px 5px 5px 5px #cdc4b8;
  background-color: #f7f1ea;
  margin: 5px 0;
  padding: 10px;
  flex: 1 1;
  color: #878787;
  border-radius: 5px;
  text-align: left;
  margin: 5px;
}

.InterfaceGame_informations-menu-button:hover,
.InterfaceGame_informations-menu-button.--active {
  background: linear-gradient(to right, #5096e8, #80d0c7);
  color: white;
}

/* INFORMATIONS */

.InterfaceGame_informations-contents {
  display: flex;
  margin-top: 20px;
}

.InterfaceGame_informations-content {
  margin: 5px;
  border-radius: 10px;
  background-color: #ffffff;
  flex: 1 1;
}

.InterfaceGame_informations-content-title {
  background-color: #52a0e2;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  padding: 5px;
}

.InterfaceGame_informations-content-title-elements {
  text-align: center;
  color: #878787;
}

.InterfaceGame_informations-content-title-element-text {
  font-weight: bold;
}

.InterfaceGame_informations-plus {
  text-align: center;
  color: #878787;
  max-width: 50%;
}

@media (max-width: 1100px) {
  .InterfaceGame_actions-contents-left-top-content {
    flex-direction: row;
    justify-content: space-around;
  }

  .InterfaceGame_informations-closed {
    transform: translateX(92%);
  }
}

@media (max-width: 975px) {
  .InterfaceGame {
    font-size: 0.9vw;
  }

  .InterfaceGame_actions-contents-left-top-header img {
    height: 40px;
    width: 40px;
    margin-top: 5px;
  }

  .InterfaceGame_actions-contents-right-outer {
    height: 150px;
  }

  .InterfaceGame_actions-contents-left-top-contents {
    margin: 10px 5px;
    justify-content: center;
  }

  .InterfaceGame_informations-closed {
    transform: translateX(88%);
  }
}
