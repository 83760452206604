.RestaurantTMS_background img {
  height: 100%;
  width: 100%;
  background-size: cover;
}

.RestaurantTMS header {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 12;
}

.RestaurantTMS {
  max-width: 160vh;
  width: 100%;
  z-index: 0;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.RestaurantTMS-img-div {
  position: relative;
}
.RestaurantTMS-img-div img {
  max-height: 100vh;
}

.CorridorTMS_head {
  position: absolute;
  width: 7%;
  height: 10%;
  animation: handleHead 6s infinite;
  top: 47.6%;
  left: 44.7%;
}

@keyframes handleHead {
  from {
    background: url(../../../../../../img/tms/tete-youverts.svg) no-repeat;
    background-size: contain;
    transform: rotate(-5deg);
  }
  16% {
    background: url(../../../../../../img/tms/tete-youverts.svg) no-repeat;
    background-size: contain;
  }
  20% {
    background: url(../../../../../../img/tms/tete-yfermés1.svg) no-repeat;
    background-size: contain;
  }
  24% {
    background: url(../../../../../../img/tms/tete-youverts.svg) no-repeat;
    background-size: contain;
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  61% {
    background: url(../../../../../../img/tms/tete-youverts.svg) no-repeat;
    background-size: contain;
  }
  65% {
    background: url(../../../../../../img/tms/tte-yfermés2.svg) no-repeat;
    background-size: contain;
  }
  69% {
    background: url(../../../../../../img/tms/tete-youverts.svg) no-repeat;
    background-size: contain;
  }
  75% {
    transform: rotate(0deg);
  }
  to {
    background: url(../../../../../../img/tms/tete-youverts.svg) no-repeat;
    background-size: contain;
    transform: rotate(-5deg);
  }
}
