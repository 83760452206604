.LockerTwo_image,
.LockerTwo {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.LockerTwo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.LockerTwo.Modal_description {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LockerTwo_buttons {
  position: absolute;
  display: flex;
  width: 36%;
  top: 36%;
  left: 50%;
  transform: translateX(-53%);
}

.LockerTwo_button {
  width: 100%;
  margin: 1vw;
}
