.crossword {
  background-color: #ffffff;
  .content {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fliving-room%2Fobjects%2Fcrossword%2FJOURNAL.svg?alt=media&token=ed5b92d6-374a-4947-8612-27958c840292);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5px 66px 54% 5px;
    p {
      color: #000000;
      font-size: 10px;
    }
    .contents {
      display: flex;
      margin-top: 20px;
      .content {
        margin: 0 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 30%;
        .big,
        .medium {
          color: #204773;
        }
        .big {
          font-size: 50px;
          margin: 0;
        }
        .medium {
          font-size: 20px;
          margin-top: -70px;
        }
      }
    }
  }
  .bar {
    background-color: black;
    width: 3px;
  }
}
