.plutchik-wheel-type {
  color: white;
  font-size: x-large;
  background-color: rgba(0, 0, 0, 0.2901960784);
  border-radius: 10px;
  position: absolute;
  top: 15%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .card {
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        height: 150px;
        border-radius: 5%;
      }
      .double {
        height: 100px;
      }
      .responses {
        .response {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20%;
        }
      }
    }
    svg {
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .storyline {
    flex-direction: row;
  }
}
