@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 1.2em;
    color: #000;
    font-weight: 700;
  }

  img {
    margin: 20px;
    width: 50%;
  }
}

.coin {
  @include keyframes(rotateAnimation) {
    0% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(180deg);
    }
  }
  animation-name: rotateAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.camera-icons {
  display: flex;
  padding: 10px;
  background-color: grey;
  border-radius: 5px;
}
