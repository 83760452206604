.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
  width: 50%;
}

.code-and-text {
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: 0.5rem;
  }
}

.block-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;

  button {
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
  }
  .block-code-image {
    position: absolute;
    height: 90px;
  }
}

#number {
  position: absolute;
  font-size: 25px;
  font-weight: 600;
}

#text {
  color: black;
  font-size: 20px;
}

.text {
  color: #331a7c;
  font-size: 20px;
  margin-top: 1rem;
}
