/* As the global position, size etc. of the wheel can change from one game/room to another,
  nothing is set here, except some specific style on <g> and <text> that you should be kept.
  Ensure the text is visible enough and defines the petal behaviour on hover and when active */

.svg-g {
  // transition: all 1s;

  path:hover {
    filter: brightness(1.5);
    transition: all 1s;
  }

  path:active {
    transition: all 1s;
  }
}

.svg-text {
  font-size: 14px;
  font-weight: 600;
}

.big-font-size {
  font-size: 15px;
}
