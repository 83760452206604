.message {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 80%;
  position: absolute;
  top: 30%;
  margin-right: 10%;
  .bubbleContainer {
    height: 100%;
    position: relative;
    width: 70%;
    img {
      left: -136px;
      position: absolute;
      top: -60px;
    }
    .text {
      width: 100%;
      height: 90%;
      padding: 2rem 2rem 2rem 7rem;
      // background: linear-gradient(#EBE9FF, #4D4095);
      background: linear-gradient(150deg, #8f8e92, #484a65);
      border-radius: 35px;
    }
  }
}
