.books {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .contents,
  .inventory {
    display: flex;
    button {
      text-decoration: none;
      background-color: transparent;
      border: none;
      img {
        height: 200px;
      }
    }
    .content {
      :hover {
        border: 2px solid white;
      }
    }
    .checked {
      border: 2px solid white;
    }
  }
  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
