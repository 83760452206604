.drawer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .inputs {
    width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      position: absolute;
      border: none;
      background-color: transparent;
      color: transparent;
      font-size: 134px;
      z-index: 100;
      width: 100%;
      right: 0;
      height: 100px;
    }
    .forms {
      display: flex;
      .number {
        height: 90px;
        width: 90px;
        background-color: rgba(222, 221, 234, 0.5803921569);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: xxx-large;
        margin: 20px;
      }
    }
  }
  .submit {
    margin-top: 5%;
  }
}
