.ObjectInInventory {
  position: absolute;
  width: 15%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: addIinventoryWoosh 0.6s ease-out forwards;
  animation-fill-mode: forwards;
  z-index: 100;
}
@keyframes addIinventoryWoosh {
  from {
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: 1;
  }
  to {
    left: 10%;
    top: 1%;
    opacity: 0;
    transform: scale(0.4);
  }
}
