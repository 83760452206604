.Calculator {
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.display {
  font-size: 22px;
  color: white;
  padding: 14px;
  width: 100%;
  text-align: right;
  max-width: 240px;
  grid-column: 1 / -1;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  flex-direction: column;
  padding: 10px;
  word-wrap: break-word;
  word-break: break-all;
}

.Calculator button {
  border: none;
  font-size: 20px;
  margin: 5px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.Calculator button:hover {
  opacity: 0.8;
}

.Calculator button.dark-gray {
  background-color: darkgray;
  color: white;
}

.Calculator button.orange {
  background-color: #ff8c00;
  color: white;
}

.Calculator button.darker {
  background-color: #404040;
  color: white;
}

.Calculator button.larger {
  background-color: #404040;
  color: white;
  width: 45%;
  border-radius: 30px;
  font-size: 25px;
}
