.intro-container {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 10;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.intro-message {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  p {
    text-align: justify;
    white-space: pre-wrap;
  }
}
