.Patients {
  position: absolute;
  bottom: 43vh;
  left: 0;
  display: flex;
}

/* Gael */
.Patient_1 {
  position: absolute;
  height: 401px;
  margin-top: 14vh;
  width: 203.25px;
}

/* Camille */
.Patient_2 {
  position: absolute;
  height: 401px;
  margin-top: 15vh;
  width: 173.57px;
}

/* Victor */
.Patient_3 {
  position: absolute;
  position: absolute;
  height: 418px;
  margin-top: 14vh;
  width: 250px;
}

/* Moha */
.Patient_4 {
  position: absolute;
  position: absolute;
  height: 390px;
  margin-top: 17vh;
  width: 220.69px;
}

/* Sabrine */
.Patient_5 {
  position: absolute;
  position: absolute;
  height: 408px;
  margin-top: 17vh;
  width: 174.27px;
}

/* Josette */
.Patient_6 {
  position: absolute;
  position: absolute;
  height: 400px;
  margin-top: 18vh;
  width: 166.23px;
}

/* Joseph */
.Patient_7 {
  position: absolute;
  position: absolute;
  height: 396px;
  margin-top: 17vh;
  width: 219.55px;
}

/* Aurélien */
.Patient_8 {
  position: absolute;
  position: absolute;
  height: 414px;
  margin-top: 16vh;
  width: 211.91px;
}

/* Yohan */
.Patient_9 {
  position: absolute;
  height: 401px;
  margin-top: 22vh;
  width: 203.25px;
}

/* Louise */
.Patient_10 {
  position: absolute;
  position: absolute;
  height: 395px;
  margin-top: 20vh;
  width: 178.57px;
}

.Patients button {
  margin-top: 150px;
}

.bulle--active {
  width: 480px;
  border: none;
  background: white;
  border-radius: 20px;
  background: white;
  box-shadow: 0 0 50px -10px rgb(34 193 195);
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  margin: 0 20px;
  color: #5f5f5f;
}

.bulle--active::after {
  content: '';
  position: absolute;
  width: 60px;
  height: 50px;
  right: calc(10% + 49px);
  bottom: -35px;
  background: white;
  box-shadow: 0 0 50px -10px rgb(34 193 195);
  -webkit-clip-path: polygon(38% 0, 100% 100%, 100% 0);
  clip-path: polygon(25% 0, 100% 100%, 100% 0);
}

.bulle--not-active {
  width: 300px;
  color: transparent;
  padding: 15px;
  border: none;
  padding: 15px;
  margin: 0 20px;
}

.bulle--not-active::after {
  width: 60px;
  height: 50px;
}
