.ModalTrickyVacc-Background p {
  font-weight: 600;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0px;
  font-size: 2vw;
}

.ModalTrickyVacc-Background {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalTrickyVacc-Scoreboard-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 30px;
}

.ModalTrickyVacc-Container-Scores {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ModalTrickyVacc-Scoreboard-Scores {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  background: #fff;
}

.ModalTrickyVacc-Scoreboard-info {
  width: 100%;
  display: flex;
  padding: 15px 0px 15px 0px;
  /* background-image: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  ); */
  color: #fff;
  text-align: center;
}

.ModalTrickyVacc-Scoreboard-players {
  width: 100%;
  display: flex;
  padding: 30px 0px 10px 0px;
  background: #fff;
  color: #666666;
  text-align: center;
}

.ModalTrickyVacc-Scoreboard-User {
  width: 100%;
  display: flex;
  padding: 30px 0px 10px 0px;
  /* background-image: linear-gradient(
    90deg,
    #e76322,
    #d93a5b,
    #cf1c83,
    #792887,
    #552d88
  ); */
  color: #fff;
  text-align: center;
}

.ModalTrickyVacc-Scoreboard-players:hover {
  background: #e8e8e8;
  font-weight: bold;
}

.col10-Vacc {
  width: 10%;
  color: #e4638c;
  font-weight: 600;
  font-size: 1.5vw;
}

.col20-Vacc {
  width: 20%;
  cursor: pointer;
  color: #e4638c;
  font-weight: 600;
  font-size: 1.5vw;
}

.col40-Vacc {
  width: 40%;
  text-align: left;
  padding-left: 15px;
  color: #e4638c;
  font-weight: 600;
  font-size: 1.5vw;
}

.col20-Vacc-Score {
  width: 20%;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.2vw;
}

.col20-Vacc-Score-Coin {
  width: 20%;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col40-Vacc-Score {
  width: 40%;
  text-align: left;
  padding-left: 15px;
  font-weight: 600;
  font-size: 1.2vw;
}

.scoreboard-pseudo {
  font-weight: bold;
  color: #e3505d;
}

.scoreboard-rank {
  font-weight: bold;
  color: #4d3990;
}

.score-selected {
  border: 1px solid #fff;
  border-radius: 5px;
}

.scoreboard-user {
  font-weight: bold;
  color: #fff;
}
.ModalTrickyVacc-Congrat-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 70%;
  background: #fff;
  border-radius: 5px;
}

.ModalTrickyVacc-Bravo {
  width: 70%;
  /* margin: -5px auto; */
  margin: 20px;
  text-align: center;
  position: relative;
  padding: 10px 0px;
  /* background-image: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  ); */
  /* box-shadow: 0px 0px 5px #454545;
  border-radius: 10px/50%; */
}

.ModalTrickyVacc-Bravo h2 {
  text-transform: uppercase;
  font-size: 4vw;
  color: #fff;
  margin: 0px;
}

.ModalTrickyVacc-congrat-p {
  text-align: center;
  color: #000;
  width: 75%;
  margin: 0px auto;
}

.ModalTrickyVacc-Form {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.Congrat-Bottom {
  display: flex;
  width: 100%;
}

.ModalTricky-Form {
  width: 50%;
}

.ModalTricky-Fox {
  width: 50%;
  position: relative;
}

.Congrat-Input-Large {
  display: flex;
  width: 90%;
  margin: auto;
}

.Congrat-Input-Medium {
  display: flex;
  width: 90%;
  margin: auto;
}

.Congrat-Input-Large input,
.Congrat-Input-Medium input {
  margin: 5px;
  width: 100%;
  text-align: center;
  padding: 5px 0px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.Congrat-Input-Large input {
  width: 100%;
}

::placeholder {
  color: #d9d9d9;
}

/* FOX */

.FoxBody-Congrat img {
  z-index: 1;
  bottom: 4%;
  right: 22%;
  width: 50%;
  position: absolute;
}

.FoxBody-Congrat .FoxBody-Congrat {
  width: 350px;
  height: 20%;
  position: relative;
  bottom: 0;
}

.FoxFur-Congrat img {
  position: absolute;
  z-index: 1;
  bottom: 32%;
  right: 40%;
  animation: breathing 6s ease infinite;
}

.FoxHead-Congrat img {
  position: absolute;
  z-index: 2;
  bottom: 46%;
  right: 31%;
  width: 42%;
  animation: movinghead 10s ease infinite;
}

.FoxHead-Congrat img:hover {
  position: absolute;
  bottom: 46%;
  right: 31%;
  width: 42%;
  animation: movingheadfast 0.5s ease;
}

.Score-Tricky-coin {
  /* transform: rotateY(-180deg); */
  width: 1.5vw;
  margin-right: 5%;
  /* animation: rotateMe 5s linear infinite; */
  animation: rotateItCoinScore 2s infinite;
}

.Loader-ScoreBoard {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes rotateItCoinScore {
  from {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes movinghead {
  from {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(14deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes movingheadfast {
  from {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes breathing {
  from {
    width: 30%;
  }
  50% {
    width: 32%;
    right: 40.5%;
  }
  to {
    width: 30%;
  }
}
