.ModalZoom {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalNotZoom {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 35%;
  z-index: 11;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalZoom_blur {
  position: absolute;
  filter: blur(2px);
  z-index: 1;
  width: 100%;
  height: 100%;
  animation-fill-mode: forwards;
}

.ModalZoom_description-image {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalZoom_description-content {
  height: 50%;
}
