.modal-position {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    132.64deg,
    rgba(155, 153, 160, 0.51) 8.12%,
    rgba(37, 35, 44, 0.595) 83.65%
  );
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75%;
  width: 50%;
  z-index: 5;
}

.modal-content {
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 0px 0px 20px 20px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(118, 76, 239);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: rgb(245, 245, 245);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(118, 76, 239);
    background-color: #555;
  }

  h1 {
    color: #331a7c;
    font-size: 36px;
    margin-bottom: 1rem;
  }

  .text {
    color: #331a7c;
    font-size: 20px;
    margin-top: 1rem;
  }
}
