.RoomName {
  color: #fff;
  font-size: 1.5rem;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  position: absolute;
  left: 2%;
  height: 10vh;
  transform: translateX(-50%);
  border-radius: 0 0 15px 15px;
  width: 30vw;
}

.RoomName-content {
  display: flex;
  align-items: center;
}
.RoomName-content p {
  font-size: 1.3vw;
}
