.CorridorTMS_background img {
  height: 100%;
  width: 100%;
  background-size: cover;
}

.CorridorTMS header {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 12;
}

.CorridorTMS {
  max-width: 160vh;
  width: 100%;
  z-index: 0;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.CorridorTMS-img-div {
  position: relative;
}
.CorridorTMS-img-div img {
  max-height: 100vh;
}
