.ListObjects--light-switch-on {
  cursor: url('../../../../../images/image-2.png') 50 50, default;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-size: contain;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  background-size: cover;
}
