.Settings {
  padding: 5px;
}

.Settings p {
  color: #272929 !important;
}

.Settings_firstTitle {
  text-align: center;
}

.Settings_secondTitle {
  margin: 10px 0 !important;
}

.Settings_secondTitle,
.Settings_botton-contents-subtitle {
  font-size: smaller;
}

.Settings_title {
  font-weight: 600 !important;
}
