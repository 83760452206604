.wires-panel {
  position: absolute;
  bottom: 0;
  z-index: 5;
}

.wires-lights {
  position: absolute;
  bottom: 177px;
  left: 70px;
  height: 356px;
  width: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    width: 20px;
    position: relative;
  }

  img:nth-of-type(3) {
    bottom: 2px;
  }
  img:nth-of-type(4) {
    bottom: 3px;
  }
  img:nth-of-type(5) {
    bottom: 5px;
  }
  img:nth-of-type(6) {
    bottom: 6px;
  }
  img:nth-of-type(7) {
    bottom: 8px;
  }
  img:nth-of-type(8) {
    bottom: 10px;
  }
}

.wires-start-container {
  position: absolute;
  top: 7.3%;
  left: 21%;
  z-index: 6;
  width: 30px;
  height: 63%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  img {
    width: 150%;
    height: 150%;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 634px;
    height: 100%;
  }
}

.wires-end-container {
  position: absolute;
  top: 8%;
  right: 28.4%;
  width: 30px;
  height: 63%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
