.ModalRobot p {
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
}

.ModalRobot {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 13;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalRobot_blur {
  position: absolute;
  filter: blur(2px);
  z-index: 1;
  width: 100%;
  height: 100%;
  animation: ModalRobotActionBlur 0.2s ease;
  animation-fill-mode: forwards;
}

@keyframes ModalRobotActionBlur {
  from {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }
}

.ModalRobot_description {
  z-index: 3;
  width: 100%;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ModalRobot_description-contents {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.ModalRobot_description-text {
  color: #fff;
  height: 100%;
  padding: 2%;
}

.ModalRobot_enigma-text {
  width: 65%;
}
.ModalRobot_enigma-actions {
  width: 35%;
}
.ModalRobot_enigma-actions p {
  margin: 10px;
}

.ModalRobot_enigma-actions-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ModalRobot_enigma-text,
.ModalRobot_enigma-actions {
  text-align: center;
  text-transform: uppercase;
  padding: 0px 15px;
}
.ModalRobot_enigma-text p {
  font-weight: bold;
  font-size: 1vw;
}
.ModalRobot_enigma-text h4 {
  font-size: 2vw;
  margin: 15px 0px;
  opacity: 80%;
}

.ModalRobot_enigma-actions .react-time-picker {
  width: 70%;
}

.ModalRobot_enigma-actions .Input {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ModalRobot_description-bubble {
  background-image: linear-gradient(
    261.23deg,
    #fdf53f -32.73%,
    #e0615d 84.04%,
    #d93c65 112.85%
  );
  display: flex;
  position: relative;
  align-items: center;
  margin-left: 7%;
  border-radius: 25px;
  width: 70vw;
  height: 19vw;
}
.ModalRobot_description-content {
  background-image: linear-gradient(
    261.23deg,
    #fdf53f -32.73%,
    #e0615d 84.04%,
    #d93c65 112.85%
  );
  display: flex;
  position: relative;
  align-items: center;
  margin-left: 7%;
  border-radius: 25px;
}
.ModalRobot_description-content:before,
.ModalRobot_description-bubble:before {
  content: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FObesity%2Fassets%2Fobjects%2Farrow_robot.svg?alt=media&token=dd915886-108d-428b-b4e2-1ee73ee277b5');
  position: absolute;
  left: -60px;
  top: 14%;
  z-index: -1;
}

.Input_content {
  border-radius: 5px;
  border: none;
  height: 3vh;
  width: 70%;
  text-align: center;
}

.Input_label {
  font-weight: bold;
  opacity: 80%;
  margin: 10px 0px 5px 0px;
  font-size: 1.5vw;
}

.ModalRobot_enigma {
  display: flex;
  align-items: center;
}

.ModalRobot .react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
  border: 1px solid white;
  border-radius: 20px;
  background-color: #c2cbd5b5;
  color: white;
}

.ModalRobot .react-time-picker__inputGroup input {
  color: white;
}

.ModalRobot .react-time-picker__clear-button svg,
.ModalRobot .react-time-picker__calendar-button svg,
.ModalRobot .react-time-picker__button:enabled:hover {
  stroke: white !important;
}

.ModalRobot_description-robot {
  width: 20%;
}

.ModalRobot-enigma-actions-robots-threee-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
