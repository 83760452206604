.drawerDuck-image,
.drawerDuck {
  height: 70%;
  margin-top: 10%;
}

@keyframes shake-1 {
  0% {
    transform: translate(1px, -2px) rotate(-1deg);
  } /*
  10% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }*/
  20% {
    transform: translate(1px, 2px) rotate(0deg);
  } /*
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }*/
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  } /*
  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }*/
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  } /*
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }*/
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  } /*
  90% {
    transform: translate(-3px, 0px) rotate(1deg);
  }*/
  100% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}
