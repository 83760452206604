.video-conf-container {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #2f0352 0%, #b3adff 100%, #200b4b 100%);
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .img-container {
    width: 95%;
    height: 95%;

    .visio-background {
      position: absolute;
      width: 82%;
      top: 12%;
      right: 9%;
    }

    .company {
      position: absolute;
      width: 33%;
      top: 20%;
      right: 57%;
    }

    .dialogue-container {
      position: absolute;
      width: 46%;
      height: 48%;
      top: 20%;
      right: 10%;
    }
  }
}
