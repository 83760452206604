.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em;
  gap: 1rem;
  width: 60%;
  p {
    font-size: 1.4em;
    font-weight: 400;
  }
  .puzzle {
    width: 50vw;
    max-width: 60vh;
    margin: 8%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    img {
      cursor: pointer;
    }
  }
}
