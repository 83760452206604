.spot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .remote {
    display: flex;
    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      .action {
        margin: 10px;
        height: 30px;
        width: 30px;
        border-radius: 20px;
        border: none;
        text-decoration: none;
      }
      .red {
        background-color: #8a2b36;
      }
      .blue {
        background-color: #28358a;
      }
      .yellow {
        background-color: yellow;
      }
      .green {
        background-color: #3a8a3a;
      }
    }
  }
  .description {
    p {
      color: white;
    }
  }
}
