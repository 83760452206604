.note-application {
  position: absolute;
  top: 10%;
  img {
    height: 100px;
    width: 100px;
    margin-bottom: 25%;
    position: absolute;
    top: 23%;
  }
}
