.enigma-container {
  position: absolute;
  bottom: -4px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.enigma-part-2-container {
  position: absolute;
  bottom: -4px;
  width: 100%;
  display: flex;
  height: 600px;
  justify-content: flex-start;
  align-items: flex-end;
}
