@use './assets/css/mixins' as mixins;

#img-background {
  @include mixins.opacitySwitch(opacity, 0.4, 1);
  animation-name: opacity;
  animation-duration: 3s;
}

.img-blur {
  filter: blur(5px);
}

.modal-position {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
