.email {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2FMAIL1.svg?alt=media&token=ad7b5b2e-e172-4e6e-a84a-c59400e95734);
  padding: 3%;
  font-family: 'worksans', 'Montserrat', 'Comfortaa', Arial, Helvetica, sans-serif;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  border-radius: 12px;
  position: absolute;
  top: 19%;
  width: 69%;
  p {
    color: #242735;
  }
  .messages {
    margin: 2% 2% 0 2%;
    p {
      color: #242735;
      margin: 5px;
    }
    .error {
      background-color: #e27bf9;
      border-radius: 30px;
      padding: 3px;
      color: white;
      display: flex;
      align-items: center;
      width: 560px;
      margin: 20px 0;
      img {
        height: 30px;
      }
      p {
        margin-left: 10px;
        color: white;
      }
    }
    input {
      background-color: transparent;
      border: none;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.5) 70%,
        transparent 30%
      );
      background-position: left bottom;
      background-repeat: repeat-x;
      background-size: 0.6vw 2px;
    }
    .hint {
      display: inline-block;
      background: #242735;
      color: white;
      text-decoration: none;
      margin: 10px;
      padding: 2px 10px;
      border-radius: 20%;
    }
  }

  button {
    margin: 0 5% 2% 5%;
  }
}

@media screen and (max-width: 1200px) {
  .email {
    p {
      font-size: 13px;
    }
    .messages {
      p {
        font-size: 13px;
      }
    }
  }
}
