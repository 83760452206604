.ChangingRoom-locker3 img {
  position: absolute;
  height: 30.6%;
  right: 7.5%;
  top: 17.8%;
}

.ChangingRoom-locker4 img {
  position: absolute;
  height: 26.3%;
  left: 22.5%;
  bottom: 25.8%;
}
