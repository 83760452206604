.morseModal {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  padding: 1rem;
  height: 18vh;
  width: 100%;
  margin-right: 2rem;

  .content-right-text-morse {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 60%;
    > div {
      display: flex;
      justify-content: center;
      position: relative;
      > img {
        height: 1.2vw;
        margin: 0px 5px;
      }
    }
    > p {
      font-size: 1.4vw;
      color: white;
      margin: 0;
    }
  }
  .content-left-remote {
    width: 40%;
    display: flex;
    justify-content: center;
    .background-remote {
      position: absolute;
      width: 25%;
      img {
        position: relative;
        width: 100%;
      }
      .remoteControl-image-court > img {
        position: absolute;
        left: 37%;
        top: 20%;
        width: 13%;
        z-index: 1;
      }
      .remoteControl-image-long > img {
        position: absolute;
        left: 50%;
        top: 20%;
        width: 13%;
        z-index: 1;
      }
      .remoteControl-image-validate > img {
        position: absolute;
        left: 75%;
        top: 31%;
        width: 15%;
        z-index: 1;
      }
      .remoteControl-image-erase > img {
        position: absolute;
        left: 10%;
        top: 31%;
        width: 15%;
        z-index: 1;
      }
    }
  }
}
