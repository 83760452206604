.code-image,
.code {
  height: 100%;
}

.code {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.code.Modal_description {
  display: flex;
  align-items: center;
  justify-content: center;
}
