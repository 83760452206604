.Zoom--day,
.Zoom--night {
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
  background-size: unset;
  height: 100%;
  background-color: #100f0fd4;
}

.Zoom--day img {
  height: 100%;
}

.Modal_description_button {
  position: absolute;
  bottom: 0;
}
