.video-application {
  position: absolute;
  top: 15%;
  right: 6%;
  font-family: 'worksans', 'Montserrat', 'Comfortaa', Arial, Helvetica, sans-serif;
  border-radius: 6px;
  background-color: #262529;
  .header {
    background-color: #e4e2ea;
    height: 25px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    align-items: center;
    align-items: center;
    padding: 1%;
    img {
      margin: 0 5px;
    }
  }
  .tab {
    align-items: center;
    justify-content: space-around;
    display: flex;
    width: 100%;
    padding: 10px;
    align-items: center;
    justify-content: center;
  }
  .tab-open {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .list {
      width: 100%;
      .persona {
        text-decoration: none;
        border: none;
        cursor: pointer;
        margin: 5px;
        border: 3px solid white;
        cursor: pointer;
        img {
          width: 50px;
        }
      }
      .transparent {
        background-color: transparent;
      }
      .red {
        background-color: red;
      }
      .pink {
        background-color: pink;
      }
      .blue {
        background-color: blue;
      }
      .green {
        background-color: green;
      }
      .yellow {
        background-color: yellow;
      }
    }
  }
  .tab-close {
    flex-direction: column;
    .inputs {
      width: 80%;
      margin: 5%;
      input {
        position: absolute;
        border: none;
        background-color: transparent;
        color: transparent;
        height: 22%;
        width: 77%;
        font-size: 134px;
      }
      .forms {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .number {
          height: 65px;
          width: 65px;
          background-color: #888791;
          border-radius: 5px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 45px;
        }
      }
    }
  }
  .error {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    color: white;
    margin: 5px;
  }
}
