.LockerOne_image,
.LockerOne {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.LockerOne {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.LockerOne.Modal_description {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LockerOne_buttons {
  position: absolute;
  display: flex;
  width: 41%;
  left: 50%;
  top: 43%;
  transform: translateX(-50.5%);
}

.LockerOne_button {
  width: 100%;
  margin: 1vw;
}
