/* .WelcomeRPS_background {
  position: absolute;
} */
@font-face {
  font-family: 'digital-7';
  src: url('../../../../../fonts/digital-7.mono.ttf');
}

.numberPatient {
  aspect-ratio: 1/1;
  width: 5%;
  position: absolute;
  left: 35%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1vw;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 0px 0.2vw rgb(0 0 0 / 20%);
  color: white;
  font-size: 2vw;
  font-family: 'digital-7';
}

.WelcomeRPS_background img {
  height: 100%;
  width: 100%;
  background-size: cover;
}

.WelcomeRPS header {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 12;
  height: 10vh;
}

.WelcomeRPS {
  max-width: 165vh;
  z-index: 0;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.WelcomeRPS-img-div {
  position: relative;
}
.WelcomeRPS-img-div img {
  max-height: 100vh;
  width: 100%;
}

.WelcomeRPS .Modal_description-text-typewriter p {
  font-size: large;
}
