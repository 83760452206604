.enigma-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: flex-start;
}
