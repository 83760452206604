.wardrobe {
  .message {
    position: absolute;
    z-index: 1;
    margin: 10px;
    font-size: 22px;
    bottom: 45%;
    right: 22%;
    width: 25%;
  }
  .key {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 5%;
    img {
      transform: rotate(90deg);
      margin-bottom: 50px;
    }
  }
  .mirror {
    position: absolute;
    z-index: 2;
    bottom: 0%;
    left: 27%;
    height: 60%;
    width: 23%;
    .post-it {
      text-align: center;
      background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fliving-room%2Fobjects%2Fcrossword%2FPOST-IT%20PLAISIR.svg?alt=media&token=8157b2f6-9b62-4fb1-aeb1-b520a45eca7d);
      display: flex;
      align-items: center;
      width: 65px;
      height: 70px;
      margin: 0px;
      padding: 2px;
      justify-content: center;
      text-decoration: none;
      border: 1px solid black;
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
      p {
        color: black;
        font-size: x-small;
      }
    }
    .post-it-1 {
      transform: rotate(30deg);
      top: 10px;
      right: 30px;
    }
    .post-it-2 {
      transform: rotate(-30deg);
      top: 10%;
      right: 50%;
    }
    .post-it-3 {
      transform: rotate(60deg);
      bottom: 25%;
      left: 10%;
    }
    .post-it-4 {
      transform: rotate(-45deg);
      top: 10px;
      left: 30px;
    }
    .post-it-5 {
      transform: rotate(25deg);
      top: 70px;
      right: 30px;
    }
    .post-it-6 {
      transform: rotate(-55deg);
      top: 70px;
      left: 30px;
    }
    .post-it-7 {
      transform: rotate(45deg);
      top: 146px;
      left: 10px;
    }
    .post-it-8 {
      transform: rotate(-25deg);
      bottom: 20%;
      right: 10%;
    }
    .post-it-9 {
      transform: rotate(-10deg);
      bottom: 35%;
      right: 30%;
    }
    .post-it-10 {
      transform: rotate(70deg);
      top: 40%;
      right: 4%;
    }
  }
  .actions {
    position: absolute;
    z-index: 2;
    bottom: 0%;
    right: 0%;
  }
  .background {
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
  }
}
