@font-face {
  font-family: 'worksans';
  src: url('../../../../../../assets/fonts/worksans-black.ttf');
}

.desktop {
  background-repeat: no-repeat;
  height: 90vh;
  position: relative;
  width: 100vw;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  border: none;
  background-color: transparent;
  font-family: 'worksans', 'Montserrat', 'Comfortaa', Arial, Helvetica, sans-serif;

  &.lock {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2FMCP_ORDI1.svg?alt=media&token=c62185b4-58bc-4e40-be84-cb2b54834163);
    .screen {
      height: 62%;
      width: 50%;
      margin: 14% 27%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .forms {
        margin: 23% 0 1% 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        img {
          height: 30px;
          width: 30px;
        }
        .error {
          height: 42%;
          width: 300px;
          background-color: #e27bf9;
          border-radius: 30px;
          padding: 5%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            margin-left: 10px;
          }
        }
        .inputs {
          display: flex;
          align-items: center;
          input {
            height: 43%;
            text-decoration: none;
            color: #8b8b8b;
            border: none;
            border-radius: 5px;
            padding: 10px;
          }
          button {
            height: 43%;
            display: flex;
            align-items: center;
          }
        }
      }
      .forget {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .link {
          cursor: pointer;
          background-color: transparent;
          border-top: none;
          border-left: none;
          border-right: none;
          text-decoration: none;
          color: white;
          margin-top: 10%;
          text-decoration: none;
          border-bottom-color: white;
        }
      }
    }
  }

  &.unlock {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2FMCP_ORDI2.svg?alt=media&token=a0e2a2d9-ec31-4506-b03b-1bf1554c83d1);
    display: flex;
    align-items: center;
    .screen {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .page {
        height: 100%;
        width: 100%;
      }
      .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2%;
      }
    }
  }

  &.lowbat {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2FBATTERIE-FAIBLE-2.gif?alt=media&token=f068e40d-de66-4f73-b2bb-0ab3e06fc58c);
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 1200px) {
  .desktop {
    &.unlock {
      .screen {
        margin: 55% 2% 8% 2%;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .desktop {
    &.unlock {
      .screen {
        margin: 50% 2% 8% 2%;
      }
    }
  }
}
