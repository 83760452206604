.Bedroom {
  z-index: 0;
  position: relative;
  margin: auto;
  overflow: hidden;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Fbackgrounds%2Fintro-background.svg?alt=media&token=1b191a05-95f0-44d3-b87c-33930f10a54b);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
}

.Bedroom header {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 12;
  height: 10vh;
}

.Bedroom-img-div {
  z-index: 0;
  position: initial;
  margin: auto;
  overflow: hidden;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  text-decoration: none;
  background-color: transparent;
  border: none;
}

.Bedroom .Modal_description-text-typewriter p {
  font-size: large;
}

.ModalTricky-NoClick2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0%;
  right: 0%;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition-duration: 0.5s;
  cursor: url('../../../../../img/unavailable.cur'), default;
}
