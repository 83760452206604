.modal-container {
  display: flex;
  align-items: center;
  height: 85%;
  width: 100%;
  position: absolute;
  top: 0;
  flex-direction: column-reverse;
  margin: 1rem;
}

.modal {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 10%;
  display: flex;
  // flex-direction: column-reverse;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  img[class*='img-'] {
    position: absolute;
    cursor: pointer;
  }

  .img-0 {
    height: 100%;
    z-index: 5;
  }

  .img-1 {
    // height: 45%;
    // z-index: 5;
    // right: 19%;
    // top: 16%;

    height: 63%;
    z-index: 6;
    left: 20%;
    top: 14%;
  }

  .img-2 {
    // height: 63%;
    // z-index: 6;
    // left: 20%;
    // top: 14%;

    height: 14%;
    z-index: 6;
    bottom: 13%;
    right: 25%;
  }

  .img-3 {
    // height: 14%;
    // z-index: 6;
    // bottom: 13%;
    // right: 25%;

    height: 14%;
    z-index: 6;
    bottom: 23%;
    right: 43%;
  }

  .img-4 {
    // height: 14%;
    // z-index: 6;
    // bottom: 23%;
    // right: 43%;

    height: 14%;
    z-index: 6;
    right: 22%;
    top: 11%;
  }

  .img-5 {
    // height: 14%;
    // z-index: 6;
    // right: 22%;
    // top: 11%;

    height: 18%;
    z-index: 6;
    top: 10%;
    left: 39%;
  }

  .img-6 {
    // height: 18%;
    // z-index: 6;
    // top: 10%;
    // left: 39%;

    height: 45%;
    z-index: 5;
    right: 19%;
    top: 16%;
  }

  .zoomed-img {
    height: 100%;
    overflow: auto;
  }
}
