.wires-panel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.enigma-container {
  position: absolute;
  bottom: -4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
