.emotion-panel {
  display: grid;
  grid-template-columns: 100px repeat(3, 233px);
  grid-template-rows: 30px 40px 339px 35px;
  justify-items: center;
  align-items: center;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  padding: 1rem 1rem 1rem 0;
  margin-left: 90px;
  background: linear-gradient(80.62deg, #c4e1d2 3.36%, #85ccc9 98.14%) padding-box,
    linear-gradient(85.68deg, #427e89 3.95%, #224e52 97.84%) border-box;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  border: 5px solid transparent;

  .button-container {
    grid-area: 4/4/4/5;
    justify-self: end;
  }

  .close-button {
    grid-area: 1/4;
    justify-self: end;
    background-color: #10767991;
    border-radius: 20px;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: transparent;
      border: 1px solid #107679;
    }
  }

  .intensity-0-container {
    grid-area: 3/2/3/3;
    display: flex;
    flex-direction: column;
  }
  .intensity-1-container {
    grid-area: 3/3/3/4;
    display: flex;
    flex-direction: column;
  }
  .intensity-2-container {
    grid-area: 3/4/3/5;
    display: flex;
    flex-direction: column;
  }
}
