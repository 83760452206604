.bubble-and-button-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .bubble-container {
    width: 45%;
  }
}

.content-questions {
  position: absolute;
  top: 5%;
  background-color: white;
  border-radius: 10px;
  margin: 0 5%;
  width: 50%;
  right: 0;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 2%;
    p {
      color: rgba(51, 26, 124, 1);
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 38px;
      text-align: left;
    }

    .title {
      flex: 1;
    }
  }
  .responses {
    display: flex;
    flex-direction: column;
    button {
      margin: 5px;
      font-size: 14px;
    }
  }
}
