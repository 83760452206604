.grass_image,
.grass {
  height: 64%;
}

.grass {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
