.TabletRPS_background img {
  height: 100%;
  width: 100%;
  background-size: cover;
}

.TabletRPS header {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 12;
}

.TabletRPS {
  margin: auto;
  max-width: 100%;
  z-index: 0;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.Tablet-Modal {
  z-index: 1;
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  margin-top: 3%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.tablet-screen {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70%;
  max-width: 75%;
  background-image: linear-gradient(90deg, #f1e6d6, #f6f1ea);
  border-radius: 2vh;
  box-shadow: 0 0 0 2vh #464b55, 0 0 0 3vh #3f3f44, 0 0 0 4.5vh #ccd0dc;
}

.Task-zone {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  background-image: linear-gradient(135deg, #85cac4, #3894ce);
  margin-left: 2vw;
  height: 96%;
  width: 80%;
  border-top-right-radius: 2vh;
  border-top-left-radius: 2vh;
  box-shadow: 0px 0px 5px 0.5px #575656;
  padding: 0.5rem;
}

.Nurse {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.defaultZone {
  display: flex;
  flex-direction: column;
  height: 70%;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  gap: 0.8em;
  align-content: center;
}

.Tablet-img {
  position: relative;
  height: auto;
  width: 5vw;
  left: 1px;
  object-fit: fill;
}

.Task,
.Task-Container,
.usedTask,
.Good-Task,
.Bad-Task {
  position: relative;
  background-color: white;
  padding-left: 0.3vw;
  padding-right: 0.3vw;
  margin: 0.25vh;
  width: 8vw;
  height: 9vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-size: 0.6em;
  border-radius: 2vh;
  color: #575656;
  box-shadow: 0px 0px 0.5vw 0.1px #575656;
}
.Task .Good-Task {
  box-shadow: 0px 0px 0px 0.15vw #42b697, 0px 0px 0.5vw 0.1px #575656;
}

.Bad-Task {
  box-shadow: 0px 0px 0px 0.15vw red, 0px 0px 0.5vw 0.1px #575656;
}

.info {
  font-size: 0.5vw;
  margin: 0px;
  margin-bottom: 1vh;
  padding: 0.5vh;
  border-radius: 1vh;
  background-color: #ededed;
}

.info:hover {
  background-color: #bdbdbd;
}

.Task-Container {
  background-image: linear-gradient(-90deg, #e9d7c2, #e1c7a8);
  box-shadow: inset 0px 0px 10px 0.01px #a6a6a6;
}

.Button-Reset {
  display: flex;
  height: 10%;
  flex-direction: row-reverse;
}

.Button-Reset button {
  font-size: 1.2vw;
  margin-top: 0%;
  height: 3vh;
  width: 4.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-image: linear-gradient(-45deg, #2580bd, #73b9b5);
  border: 0px;
  box-shadow: 0px 0px 5px 0.01px #575656;
}

.Button-Reset button:hover {
  background-image: linear-gradient(-45deg, #01609d, #739995);
  border: none;
}

.Years {
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  background-image: linear-gradient(180deg, #eeddc8, #f4eadd);
  box-shadow: 0px 0px 5px 0.5px #575656;
  border-bottom-right-radius: 2vh;
  border-top-right-radius: 2vh;
  margin-bottom: 1vh;
}

.bubble-nurse {
  background-color: white;
  padding: 1rem;
  margin: 0 0 0.25vh 0.25vh;
  width: 12vw;
  height: 14vh;
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  border-radius: 2vh;
  color: black;
}
.bubble-nurse:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: transparent white transparent transparent;
  top: 10vh;
  left: 6.8vw;
  margin-top: -10px;
}

.Years button {
  font-size: 1.2vw;
  margin: 0.8vh;
  height: 2.5vh;
  width: 4.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: white;
  background-image: none;
  border: 0px;
  font-weight: lighter;
  box-shadow: 0px 0px 5px 0.01px #575656;
}

.Years button:hover {
  background-image: linear-gradient(90deg, #2180bd, #93b9b5);
  border: none;
}

.button-current-year button {
  color: white;
  font-weight: normal;
  background-image: linear-gradient(90deg, #3590cd, #83c9c5);
}

.usedTask {
  color: #575656;
  box-shadow: 0px 0px 10px 0.1px #575656;
  opacity: 0.5;
}

.months {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  height: 80%;
}

.month {
  font-weight: lighter;
  font-size: 0.85vw;
  display: flex;
  flex-direction: column;
  padding-top: 0.5vh;
  border-radius: 10px;
  align-items: center;
  margin: 0.25vw;
}

.Modal-Info {
  font-weight: lighter;
  display: flex;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  position: absolute;
  border-radius: 2vh;
  background-color: white;
  height: 70%;
  width: 20vw;
  bottom: 3vh;
  z-index: 1;
  padding: 5px 30px;
}

.Blur-Info {
  filter: blur(5px);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.Modal-Info h1 {
  font-size: 1.5vw;
}

.Modal-Info p {
  font-size: 1vw;
}

.Modal-Info button {
  border-radius: 0.8vw;
  font-size: 1vw;
  margin-top: 0%;
  height: 3vh;
  width: 6vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 0px;
  font-weight: lighter;
  background-image: linear-gradient(90deg, #3590cd, #83c9c5);
}

.Modal-Info button:hover {
  background-image: linear-gradient(90deg, #2180bd, #93b9b5);
  border: none;
}

.TabletRPS-img-div {
  position: relative;
  height: 100vh;
  filter: blur(8px);
  z-index: 1;
}
.TabletRPS-img-div img {
  height: 100vh;
}

@media (min-width: 1500px) {
  .tablet-screen {
    height: 75%;
    max-width: 60%;
  }
  .Task-zone {
    width: 100%;
    height: 98%;
    padding-bottom: 0.8rem;
  }
  .Task {
    font-size: 0.7em;
  }
}

.container-right {
  display: flex;
  height: 100%;
  padding: 3em;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
