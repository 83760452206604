.Timer {
  padding: 5px;
}

.Timer p {
  color: #272929 !important;
}

.Timer_firstTitle {
  text-align: center;
}

.Timer_secondTitle {
  margin: 10px 0 !important;
}

.Timer_secondTitle,
.Timer_botton-contents-subtitle {
  font-size: smaller;
}

.Timer_time {
  font-size: xx-large;
}

.Timer_title {
  font-weight: 600 !important;
}

.Timer_content {
  background-color: #fcc018;
  border-radius: 10px;
  padding: 5px;
  font-size: 12px;
}

.Timer_bar {
  height: 1px;
  width: 100%;
  background-color: #e3e3e3;
  margin-top: 20px;
}

.Timer_bottom-contents {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px !important;
}

.Timer_bottom-contents-graph {
  width: 30%;
}

.Timer_botton-contents-description {
  margin-left: 20px;
}

.Timer_botton-contents-subtitle {
  margin: 5px 0 !important;
}
