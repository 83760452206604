.bedroomTDE-background img {
  height: 100%;
  width: 100%;
  background-size: cover;
}

.bedroomTDE header {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 12;
}

.bedroomTDE {
  width: 100%;
  z-index: 0;
  max-width: 161vh;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.bedroomTDE-img-div {
  position: relative;
}
.bedroomTDE-img-div img {
  max-height: 100vh;
}

.bedroomTDE-halo img:nth-child(1) {
  position: absolute;
  pointer-events: none;
  z-index: 2;
  width: 70%;
  bottom: 22%;
  left: 16%;
}
.bedroomTDE-halo img:nth-child(2) {
  position: absolute;
  pointer-events: none;
  z-index: 2;
  width: 49%;
  bottom: 21%;
  left: 24%;
}
