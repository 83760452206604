.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  .icon {
    width: 100%;
    background-color: transparent;
    border: none;
    img {
      width: 70px;
    }
  }
}

@media screen and (max-width: '1200px') {
  .footer {
    .icon {
      img {
        width: 50px;
      }
    }
  }
}
