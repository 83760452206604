.dialogue-container {
  width: 712px;
  height: 70%;
}

.header {
  z-index: 14;
}

.dialogue-container {
  position: absolute;
  top: 0;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.dialogue-position {
  width: 55%;
  height: 70%;
}

.blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 5;
  backdrop-filter: blur(5px);
}

.bubble-and-button-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bubble-container {
  position: absolute;
  top: 0;
  z-index: 10;
}
