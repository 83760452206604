.participants {
  display: flex;
  flex-direction: column;
  align-items: center;
  &:first-child {
    margin-top: 3vh;
  }
}

@media screen and (max-width: 800px) {
  .participants {
    &:first-child {
      margin-top: 0;
    }
  }
}
