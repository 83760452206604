@font-face {
  font-family: 'WorkSans';
  src: url('../../../../assets/fonts/WorkSans-Regular.ttf') format('truetype');
}

.discussion {
  width: 100%;
  max-height: 100%;
  padding: 0px 40px 20px;
  background: linear-gradient(
    132.64deg,
    rgba(155, 153, 160, 0.51) 8.12%,
    rgba(37, 35, 44, 0.595) 83.65%
  );
  backdrop-filter: blur(10px);
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'WorkSans', sans-serif;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: white;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #764cef;
    border-radius: 3px;
  }
}

.smallView {
  padding: 0;
}
