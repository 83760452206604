.app {
  padding: 5px;
  .title {
    font-weight: 600 !important;
  }
  .firstTitle {
    text-align: center;
  }
  .top {
    .secondTitle {
      margin: 10px 0 !important;
    }
  }
}
