.Modal {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;

  p {
    font-weight: 300;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    margin: 0px;
    text-align: left;
    font-size: 26px;
  }
}

.Modal_blur {
  position: absolute;
  filter: blur(2px);
  z-index: 1;
  width: 100%;
  height: 100%;
  animation: modalActionBlur 0.2s ease;
  animation-fill-mode: forwards;
}

.Modal_description-robot {
  justify-content: center !important;
}

@keyframes modalActionBlur {
  from {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }
}

.Modal_description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  z-index: 3;
  width: 100%;
  height: 70%;
  align-items: center;
}

.modal-object-container {
  justify-content: center;
  align-items: center;
}

.Modal_description-text {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  margin: 3%;
}

.Modal_description-card {
  margin-top: 2%;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 0px;
    text-align: left;
    font-size: 1.5vw;
  }
}

.modal-object {
  height: 90%;
  width: 80%;
  background-color: white;
  border-radius: 15px;
  padding: 2rem;
  display: flex;
  flex-direction: column;

  p {
    color: black;
    font-weight: 500;
    text-align: center;
    font-size: 1.5rem;
    margin-top: 1rem;
  }

  img {
    overflow: auto;
  }
}

.Modal_description-image {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    animation: showitem 0.5s ease;
    animation-fill-mode: forwards;
    max-height: 80%;
    max-width: 80%;
  }
}

.Modal_description-button {
  height: 100%;
  width: 100%;
  background-color: transparent;
  border: none;
}

.Modal_description-content {
  height: 80%;
}

.Modal_description-content-prevention {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DesktopV2 .Modal_description-content-prevention {
  z-index: -1;
  width: 100%;
  position: absolute;
}

.Modal_description-content-prevention img {
  top: -60px;
  left: -136px;
  position: absolute;
}
.Modal_description-image--reverse img,
.Modal_description-image--reverse {
  height: 100%;
}

#bubble-container {
  width: 70%;
  height: 100%;
  position: relative;
}
