.Board_image,
.Board {
  height: 100%;
}

.Board {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.BoardTMS_background-img {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.Board_content-Patient0 {
  position: absolute;
  right: 50%;
  top: 10%;
  width: 15%;
  height: 35%;
}
.Board_content-Patient1 {
  position: absolute;
  right: 17%;
  top: 10%;
  width: 15%;
  height: 35%;
  transform: translateX(-50%);
}
.Board_content-Patient2 {
  position: absolute;
  right: 3%;
  top: 10%;
  width: 15%;
  height: 35%;
}
.Board_content-Patient3 {
  position: absolute;
  right: 36%;
  top: 55%;
  width: 15%;
  height: 35%;
}
.Board_content-Patient4 {
  position: absolute;
  right: 12%;
  top: 55%;
  width: 15%;
  height: 35%;
}

.Board_content-enigma {
  position: absolute;
  display: flex;
  width: 74%;
  left: 50%;
  top: 26%;
  height: 51%;
  transform: translateX(-50%);
}

.Board_content-button {
  text-decoration: none;
  background-color: transparent;
  border-radius: 10vw;
  width: 11vw;
  height: 11vw;
  border: none;
}

.Board_content-button img {
  width: 9vw;
  height: 9vw;
}
.Board_content-patients p {
  font-size: 1vw !important;
}

.Board_content-final {
  background-color: #4b6043;
  border: 20px solid #430d20;
  height: 90%;
  margin: 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Board_content-Explanation {
  width: 30%;
  text-align: left;
  margin-left: 2vw;
}
.Board_content-Explanation h3 {
  font-size: 2vw !important;
  margin: 2vw 0vw;
}
.Board_content-Explanation p {
  font-size: 1.6vw !important;
}

.BoardTMS_background-reflect {
  position: absolute;
  width: 79%;
  left: 11.2%;
  top: 28%;
  z-index: 1;
  pointer-events: none;
}

.Board_content-Error {
  position: absolute;
  top: 70%;
  width: 30%;
  color: red !important;
  font-weight: bold !important;
}

.Board_content-MoreInfo {
  position: absolute;
  z-index: 5;
  width: 3vw;
  height: 3vw;
  padding: 0.5vw;
  border-radius: 50%;
  font-size: 1.5vw;
  font-weight: bold;
  top: 0;
  right: 0;
}
.Board_content-MoreInfo-Close {
  border: 1px solid #000;
  background: rgba(255, 255, 255, 0.5);
  color: #000;
  position: absolute;
  border-radius: 1vw;
  padding: 0.5vw;
  z-index: 6;
  top: 0;
  right: 0;
  transform: translateX(60%) translateY(-50%);
}

.Board_content-Patient-Description {
  position: absolute;
  width: 100%;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #000 !important;
  padding: 1vw;
  border-radius: 1vw;
}
