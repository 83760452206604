.participant {
  background-color: #353b48;
  max-width: 200px;
  position: relative;
  border-radius: 5px;
  margin-bottom: 2vh;
  .video {
    background-color: #353b48;
    height: 100%;
    width: 100%;
    position: relative;
    object-fit: cover;
    border-radius: 5px;
  }

  .current {
    border-width: 6px;
    border-style: solid;
    border-image: linear-gradient(
        to right,
        rgba(0, 104, 55, 1),
        rgba(140, 198, 63, 1)
      )
      1;
  }
  .not-current {
    border-width: 4px;
    border-style: none;
    border-image: linear-gradient(to right, transparent, transparent) 1;
    border-radius: 5px;
  }

  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    font-size: 58px;
    font-family: roboto, arial;
    color: white;
    text-align: center;
    background: orange;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }

  .muted {
    color: #fff;
    right: 10px;
    position: absolute;
    top: 10px;
    border-radius: 50%;
    height: 22px;
    width: 30px !important;
  }

  .name {
    color: white;
    top: 10px;
    position: absolute;
    left: 20px;
    background-color: #15171b96;
    padding: 2%;
    border-radius: 5px;
  }

  .extra-content-current-user,
  .extra-content-animator {
    position: absolute;
    right: -25px;
    display: flex;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    color: white;
    background-color: rgba(30, 32, 35, 0.83);
    padding: 13px 3px;
    border-bottom-left-radius: 30px;
    height: 100%;
    width: 14%;
  }
}

@media (max-width: 800px) {
  .participant {
    min-height: 0px;
    max-width: 150px;
    .avatar {
      width: 80px;
      height: 80px;
      font-size: 50px;
    }
  }
}
