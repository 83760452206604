.intensity-title {
  display: flex;
  align-items: center;

  img {
    height: 35px;
    width: 34px;
    background-color: #107679;
    border-radius: 10px;
    padding: 3px 10px;
  }

  p {
    margin-left: 10px;
    font-weight: 600;
    font-size: 16px;
  }
}

.weak {
  grid-area: 2/2/2/3;
}

.medium {
  grid-area: 2/3/2/4;
}

.strong {
  grid-area: 2/4/2/5;
}
